import { Record } from 'immutable';
import { sortBy } from 'underscore';
import projectTypes from '../utils/projectTypes';
import { toHours } from '../utils/time';
import { toString } from '../utils/format';

const {
    GET_RETAINER_OVERVIEW_REQUEST,
    GET_RETAINER_OVERVIEW_SUCCESS,
    GET_RETAINER_OVERVIEW_FAILURE,

    RESET_RETAINER_OVERVIEW_REQUEST,
    RESET_RETAINER_OVERVIEW_SUCCESS,
    RESET_RETAINER_OVERVIEW_FAILURE,

    UPDATE_PROJECT_REQUEST,
    UPDATE_PROJECT_SUCCESS,
    UPDATE_PROJECT_FAILURE,

    GET_TICKETS_REQUEST,
    GET_TICKETS_SUCCESS,
    GET_TICKETS_FAILURE,

    GET_SPRINT_DATA_REQUEST,
    GET_SPRINT_DATA_SUCCESS,
    GET_SPRINT_DATA_FAILURE,

    GET_BILLING_PERIOD_DATA_REQUEST,
    GET_BILLING_PERIOD_DATA_SUCCESS,
    GET_BILLING_PERIOD_DATA_FAILURE,

    GET_SPRINT_PLAN_DATA_REQUEST,
    GET_SPRINT_PLAN_DATA_SUCCESS,
    GET_SPRINT_PLAN_DATA_FAILURE,

    UPDATE_DATA_SOURCE_REQUEST,
    UPDATE_DATA_SOURCE_SUCCESS,
    UPDATE_DATA_SOURCE_FAILURE,

    UPDATE_BILLING_PERIOD_DATA_REQUEST,
    UPDATE_BILLING_PERIOD_DATA_SUCCESS,
    UPDATE_BILLING_PERIOD_DATA_FAILURE,

    GET_BUGS_REQUEST,
    GET_BUGS_SUCCESS,
    GET_BUGS_FAILURE,

    GET_PEV_INITIAL_VALUES_REQUEST,
    GET_PEV_INITIAL_VALUES_SUCCESS,
    GET_PEV_INITIAL_VALUES_FAILURE,

    GET_PEV_LATEST_SNAPSHOT_REQUEST,
    GET_PEV_LATEST_SNAPSHOT_SUCCESS,
    GET_PEV_LATEST_SNAPSHOT_FAILURE,

    GET_NEW_PEV_SNAPSHOT_REQUEST,
    GET_NEW_PEV_SNAPSHOT_SUCCESS,
    GET_NEW_PEV_SNAPSHOT_FAILURE,

    DELETE_BILLING_PERIOD_DATA_REQUEST,
    DELETE_BILLING_PERIOD_DATA_SUCCESS,
    DELETE_BILLING_PERIOD_DATA_FAILURE,

    GET_BUG_SUMMARY_REQUEST,
    GET_BUG_SUMMARY_SUCCESS,
    GET_BUG_SUMMARY_FAILURE,

    GET_BUG_TICKET_BREAKDOWN_REQUEST,
    GET_BUG_TICKET_BREAKDOWN_SUCCESS,
    GET_BUG_TICKET_BREAKDOWN_FAILURE,

    RESET_BUG_SUMMARY_REQUEST,
    RESET_BUG_SUMMARY_SUCCESS,
    RESET_BUG_SUMMARY_FAILURE,

    RESET_TICKETS_REQUEST,
    RESET_TICKETS_SUCCESS,
    RESET_TICKETS_FAILURE,

    UPDATE_COMPONENTS_REQUEST,
    UPDATE_COMPONENTS_SUCCESS,
    UPDATE_COMPONENTS_FAILURE,

    UPDATE_BUDGETS_REQUEST,
    UPDATE_BUDGETS_SUCCESS,
    UPDATE_BUDGETS_FAILURE,

    EXPORT_SPEC_DOC_REQUEST,
    EXPORT_SPEC_DOC_SUCCESS,
    EXPORT_SPEC_DOC_FAILURE,
} = require('./agileProjectActions').constraints;

const InitialState = Record({
    isFetching: false,
    isUpdating: false,
    isRefreshing: false,
    isTicketFetching: false,
    isExporting: false,
    error: null,
    settings: {},
    billingPeriods: [],
    selectedBillingPeriod: [],
    billingPeriodPagination: [],
    selectedSprintPlan: [],
    summary: [],
    sprints: [],
    selectedSprint: [],
    sprintDataPagination: [],
    sprintDatPlanPagination: [],
    lastFetchedAt: '',
    lastRefreshedAt: '',
    tickets: [],
    ticketsPagination: {},
    uncategorised: {},
    latestPevSnapshot: {},
    pevInitialValues: {},
    updatedProject: null,
    bugSummary: {},
    bugTicketBreakdown: [],
    bugs: [],
    components: [],
    filters: {},
    statusUpdate: null,
});

export const initialState = new InitialState();

export default function agileProjectReducer(
    state = initialState,
    { payload, type },
) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case GET_RETAINER_OVERVIEW_REQUEST:
        return state
            .set('isFetching', true)
            .set('error', null)
            .set('settings', null)
            .set('updatedProject', null);

    case RESET_RETAINER_OVERVIEW_REQUEST:
    case GET_TICKETS_REQUEST:
    case GET_SPRINT_DATA_REQUEST:
    case GET_BILLING_PERIOD_DATA_REQUEST:
    case GET_SPRINT_PLAN_DATA_REQUEST:
        return state
            .set('isFetching', true)
            .set('error', null)
            .set('filters', null)
            .set('selectedBillingPeriod', [])
            .set('selectedSprintPlan', [])
            .set('selectedSprint', [])
            .set('isTicketFetching', true);

    case EXPORT_SPEC_DOC_REQUEST:
        return state.set('isExporting', true).set('error', null);
    case EXPORT_SPEC_DOC_SUCCESS:
        return state.set('isExporting', false).set('error', null);
    case EXPORT_SPEC_DOC_FAILURE:
        return state.set('isExporting', false).set('error', null);

    case UPDATE_DATA_SOURCE_REQUEST:
        return state.set('isRefreshing', true).set('error', null);
    case UPDATE_PROJECT_REQUEST:
    case UPDATE_COMPONENTS_REQUEST:
    case UPDATE_BUDGETS_REQUEST:
    case UPDATE_BILLING_PERIOD_DATA_REQUEST:
    case DELETE_BILLING_PERIOD_DATA_REQUEST:
        return state
            .set('isUpdating', true)
            .set('isFetching', true)
            .set('error', null);
    case GET_BUG_SUMMARY_REQUEST:
    case RESET_BUG_SUMMARY_REQUEST:
    case GET_BUGS_REQUEST:
        return state.set('isFetching', true).set('error', null);
    case RESET_TICKETS_REQUEST:
        return state
            .set('isFetching', true)
            .set('isTicketFetching', true)
            .set('error', null);
    case RESET_TICKETS_SUCCESS:
        return state
            .set('isFetching', false)
            .set('isTicketFetching', false)
            .set('tickets', null)
            .set('ticketsPagination', null)
            .set('error', null);
    case RESET_TICKETS_FAILURE:
        return state
            .set('isFetching', false)
            .set('isTicketFetching', false)
            .set('tickets', null)
            .set('ticketsPagination', null)
            .set('error', null);

    case GET_RETAINER_OVERVIEW_SUCCESS: {
        const {
            details,
            budgetSummary,
            billing_periods,
            sprints,
            components,
            estimates,
            update_status,
        } = payload;
        const {
            id,
            code,
            name,
            client_name,
            description,
            project_colour,
            toggl_project_id,
            toggl_task_id,
            type: projectType,
            start_at,
            billing_weeks,
            lead_dev,
            project_manager,
            project_rate,
            last_fetched_at,
            last_refreshed_at,
            status,
            fix_versions,
            planning_budget,
            graphics_budget,
            dev_budget,
            pm_testing_budget,
            dev_fixing_budget,
            pm_budget,
            dev_pm_budget,
        } = details;
        const {
            dev_most_likely,
            dev_optimistic,
            dev_pessimistic,
            test_most_likely,
            test_optimistic,
            test_pessimistic,
            pm_most_likely,
            pm_optimistic,
            pm_pessimistic,
            dev_pm_most_likely,
            dev_pm_optimistic,
            dev_pm_pessimistic,
        } = estimates;
        const value = projectTypes.find((p) => p.slug === projectType);

        const billingPeriods = billing_periods?.map((billingPeriod) => {
            const {
                dev_client_planned,
                dev_time_actual,
                dev_time_coordination,
                dev_time_tickets,
                end_date,
                hourly_rate,
                id: periodId,
                non_billable_time,
                period,
                pm_client_planned,
                pm_time_actual,
                pm_time_actual_pm,
                pm_time_actual_qa,
                spent_time_actual,
                sprints: periodSprints,
                start_date,
                time_owing,
                total_planned_time,
                unforeseen_time,
                work_planned,
                work_remaining,
            } = billingPeriod;

            return {
                dev_client_planned: toHours(dev_client_planned),
                dev_time_actual: toHours(dev_time_actual),
                dev_time_coordination: toHours(dev_time_coordination),
                dev_time_tickets: toHours(dev_time_tickets),
                end_date,
                hourly_rate,
                id: periodId,
                non_billable_time: toHours(non_billable_time),
                period,
                pm_client_planned: toHours(pm_client_planned),
                pm_time_actual: toHours(pm_time_actual),
                pm_time_actual_qa: toHours(pm_time_actual_qa),
                pm_time_actual_pm: toHours(pm_time_actual_pm),
                spent_time_actual: toHours(spent_time_actual),
                sprints: periodSprints,
                start_date,
                time_owing: toHours(time_owing),
                total_planned_time: toHours(total_planned_time),
                unforeseen_time: toHours(unforeseen_time),
                work_planned: toHours(work_planned),
                work_remaining: toHours(work_remaining),
            };
        });

        const settings = {
            id,
            projectCode: code || '',
            projectName: name || '',
            clientName: client_name || '',
            projectDescription: description || '',
            projectColour: project_colour || '',
            togglProjectId: toggl_project_id || '',
            togglPlanningDayActivitiesTaskId: toggl_task_id || '',
            projectType: value?.value || '',
            startDate: start_at || '',
            billingPeriod: billing_weeks || '',
            leadDeveloper: lead_dev?.name || '',
            projectManager: project_manager?.name || '',
            projectRate: project_rate || '',
            status,
            fixVersions: fix_versions || [],
            planningBudget: planning_budget || '',
            graphicsBudget: graphics_budget || '',
            devBudget: dev_budget || '',
            pmTestingBudget: pm_testing_budget || '',
            devFixingBudget: dev_fixing_budget || '',
            pmBudget: pm_budget || '',
            devPmBudget: dev_pm_budget || '',
            pmMostLikely: pm_most_likely,
            pmPessimistic: pm_pessimistic,
            pmOptimistic: pm_optimistic,
            devPmMostLikely: dev_pm_most_likely,
            devPmPessimistic: dev_pm_pessimistic,
            devPmOptimistic: dev_pm_optimistic,
            testMostLikely: test_most_likely,
            testPessimistic: test_pessimistic,
            testOptimistic: test_optimistic,
            devMostLikely: dev_most_likely,
            devOptimistic: dev_optimistic,
            devPessimistic: dev_pessimistic,
        };
        const componentsData = components?.map((componentData) => {
            const {
                id: componentId,
                name: componentName,
                project_id,
                due_date,
                hours_quoted,
                hours_spent,
                hours_complete,
                hours_remaining,
                hours_delta,
                perc_complete,
                perc_delta,
                ready_to_bill,
            } = componentData;
            return {
                id: componentId,
                name: componentName,
                due_date,
                project_id,
                hours_quoted: (hours_quoted).toFixed(2),
                hours_spent: (hours_spent).toFixed(2),
                hours_complete: (hours_complete).toFixed(2),
                hours_remaining: (hours_remaining).toFixed(2),
                hours_delta: (hours_delta).toFixed(2),
                perc_complete,
                perc_delta,
                ready_to_bill,
            };
        });
        return state
            .set('settings', settings)
            .set('billingPeriods', sortBy(billingPeriods, 'start_date'))
            .set('lastFetchedAt', last_fetched_at)
            .set('lastRefreshedAt', last_refreshed_at)
            .set('summary', budgetSummary)
            .set('sprints', sprints)
            .set('components', componentsData)
            .set('statusUpdate', update_status)
            .set('isFetching', false);
    }

    case RESET_RETAINER_OVERVIEW_SUCCESS:
        return state
            .set('settings', {})
            .set('summary', [])
            .set('sprints', [])
            .set('components', [])
            .set('isFetching', false)
            .set('lastFetchedAt', null)
            .set('lastRefreshedAt', null);

    case UPDATE_PROJECT_SUCCESS: {
        return state.set('isUpdating', false).set('isFetching', false);
    }

    case UPDATE_COMPONENTS_SUCCESS:
    case UPDATE_BUDGETS_SUCCESS:
        return state.set('isUpdating', false).set('isFetching', false);

    case UPDATE_BILLING_PERIOD_DATA_SUCCESS: {
        return state.set('isUpdating', false).set('isFetching', false);
    }

    case GET_TICKETS_SUCCESS: {
        const { tickets, filters } = payload;
        const pagination = {
            current: tickets.current_page,
            total: tickets.total,
            page_size: tickets.per_page,
            from: tickets.from,
            to: tickets.to,
        };

        const projectTickets = tickets.data?.map((ticket) => {
            const {
                type: ticketType,
                code,
                parent_ticket_code,
                sprints,
                status: ticketStatus,
                title,
                priority: ticketPriority,
                components,
                fix_versions,
                total_estimate_time,
                optimistic_estimate,
                pessimistic_estimate,
                unforeseen_time,
                time_spent,
                nonbillable,
                quote_time,
                original_estimate,
                time_remaining,
                quoted_estimate_delta,
                quoted_estimate_delta_perc,
                final_estimate_delta,
                final_estimate_delta_perc,
            } = ticket;

            return {
                type: ticketType,
                typeName: ticketType?.name,
                code,
                storyCode: parent_ticket_code,
                sprints,
                status: ticketStatus?.name,
                summary: title,
                priority: ticketPriority,
                components,
                fixVersions: fix_versions,
                unforeseen_time,
                quote_time,
                optimistic_estimate,
                pessimistic_estimate,
                original_estimate:
            original_estimate ?? parseFloat(original_estimate).toFixed(2),
                time_spent:
            time_spent != null ? parseFloat(time_spent).toFixed(2) : 0,
                time_remaining: toString(Number(time_remaining)),
                total_estimate_time:
            total_estimate_time ?? parseFloat(total_estimate_time).toFixed(2),
                quoted_estimate_delta:
            quoted_estimate_delta != null
                ? parseFloat(quoted_estimate_delta).toFixed(2)
                : '-',
                quoted_estimate_delta_perc:
            quoted_estimate_delta_perc != null
                ? parseFloat(quoted_estimate_delta_perc).toFixed(2)
                : '-',
                final_estimate_delta:
            final_estimate_delta != null
                ? parseFloat(final_estimate_delta).toFixed(2)
                : '-',
                final_estimate_delta_perc:
            final_estimate_delta_perc != null
                ? parseFloat(final_estimate_delta_perc).toFixed(2)
                : '-',
                nonbillable:
            nonbillable != null ? parseFloat(nonbillable).toFixed(2) : 0,
            };
        });

        return state
            .set('isFetching', false)
            .set('filters', filters)
            .set('tickets', projectTickets)
            .set('ticketsPagination', pagination)
            .set('isTicketFetching', false);
    }

    case GET_SPRINT_DATA_SUCCESS: {
        const { sprintId, data, projectId } = payload;
        const { tickets, reporting, uncategorised } = data;
        const pagination = {
            current: tickets.current_page,
            total: tickets.total,
            page_size: tickets.per_page,
            from: tickets.from,
            to: tickets.to,
        };

        const sprintTickets = tickets.data?.map((ticket) => {
            const {
                type: ticketType,
                code,
                status: ticketStatus,
                title,
                priority,
                components,
                labels,
                fix_versions,
                original_estimate,
                unforeseen_time,
                billable_time,
                time_remaining,
                total_billed,
                nonbillable,
                time_delta,
            } = ticket;

            return {
                type: ticketType,
                code,
                status: ticketStatus?.name,
                summary: title,
                priority,
                components,
                fixVersions: fix_versions,
                unforeseen_time,
                nonbillable: nonbillable.toFixed(2),
                labels,
                original_estimate: original_estimate.toFixed(2),
                time_spent: toHours(billable_time),
                time_remaining: toString(Number(time_remaining)),
                total_billed: total_billed.toFixed(2),
                time_delta:
            time_delta != null ? parseFloat(time_delta).toFixed(2) : '-',
            };
        });

        const reportingData = reporting.map((entry) => {
            const { name, billable, non_billable } = entry;

            return {
                summary: name,
                time_spent: toHours(billable),
                nonbillable: toHours(non_billable),
            };
        });

        let sprintData = [...sprintTickets, ...reportingData];

        if (
            uncategorised[0]?.billable > 0
        || uncategorised[0]?.non_billable > 0
        ) {
            uncategorised.forEach((entry) => {
                const { billable, non_billable, name } = entry;

                sprintData = [
                    ...sprintData,
                    {
                        summary: name,
                        time_spent: toHours(billable),
                        nonbillable: toHours(non_billable),
                    },
                ];
            });
        }

        const selectedSprint = {
            projectId,
            sprintId,
            data: sprintData,
        };
        return state
            .set('isFetching', false)
            .set('selectedSprint', selectedSprint)
            .set('sprintDataPagination', pagination);
    }

    case GET_BILLING_PERIOD_DATA_SUCCESS: {
        const { period, data, projectId, periodId } = payload;
        const { tickets, reporting, uncategorised } = data;
        const pagination = {
            current: tickets.current_page,
            total: tickets.total,
            page_size: tickets.per_page,
            from: tickets.from,
            to: tickets.to,
        };

        const periodTickets = tickets.data?.map((ticket) => {
            const {
                type: ticketType,
                code,
                status: ticketStatus,
                title,
                priority,
                components,
                labels,
                ssd_code,
                fix_versions,
                nonbillable,
                unforeseen_time,
                original_estimate,
                time_spent,
                time_remaining,
                total_billed,
                time_delta,
            } = ticket;

            return {
                type: ticketType,
                code,
                status: ticketStatus?.name,
                summary: title,
                priority,
                components,
                fixVersions: fix_versions,
                unforeseen_time,
                nonbillable,
                labels,
                ssdCode: ssd_code,
                original_estimate,
                total_billed: total_billed.toFixed(2),
                time_spent: time_spent.toFixed(2),
                time_remaining: time_remaining.toFixed(2),
                time_delta: time_delta.toFixed(2),
            };
        });

        const reportingData = reporting.map((entry) => {
            const { name, billable, non_billable } = entry;

            return {
                summary: name,
                time_spent: toHours(billable),
                nonbillable: toHours(non_billable),
            };
        });

        let periodData = [...periodTickets, ...reportingData];

        if (
            uncategorised[0]?.billable > 0
        && uncategorised[0]?.non_billable > 0
        ) {
            uncategorised.forEach((entry) => {
                const { billable, non_billable, name } = entry;

                periodData = [
                    ...periodData,
                    {
                        summary: name,
                        time_spent: toHours(billable),
                        nonbillable: toHours(non_billable),
                    },
                ];
            });
        }

        const selectedPeriod = {
            projectId,
            period,
            data: periodData,
            periodId,
        };

        // Check if projectId already exist in state.selectedPeriod
        const indexToUpdate = state.selectedBillingPeriod.findIndex(
            (item) => item.projectId === projectId,
        );

        if (indexToUpdate !== -1) {
        // If projectId already exist, update the existing item
            const updatedSelectedPeriod = [...state.selectedBillingPeriod];
            updatedSelectedPeriod[indexToUpdate] = selectedPeriod;

            return state
                .set('isFetching', false)
                .set('selectedBillingPeriod', updatedSelectedPeriod);
        }
        // If projectId do not exist, add the new item
        return state
            .set('isFetching', false)
            .set('selectedBillingPeriod', [
                ...state.selectedBillingPeriod,
                selectedPeriod,
            ])
            .set('billingPeriodPagination', pagination);
    }

    case GET_SPRINT_PLAN_DATA_SUCCESS: {
        const { sprintId, data, projectId } = payload;
        const { tickets } = data;
        const pagination = {
            current: tickets.current_page,
            total: tickets.total,
            page_size: tickets.per_page,
            from: tickets.from,
            to: tickets.to,
        };

        const sprintTickets = tickets?.data.map((ticket) => {
            const {
                type: ticketType,
                code,
                status: ticketStatus,
                title,
                priority,
                components,
                labels,
                fix_versions,
                original_estimate,
                time_remaining,
            } = ticket;

            return {
                type: ticketType,
                code,
                status: ticketStatus?.name,
                summary: title,
                priority,
                components,
                fixVersions: fix_versions,
                labels,
                original_estimate,
                time_remaining: time_remaining.toFixed(2),
            };
        });

        const sprintData = [...sprintTickets];

        const selectedSprintPlan = {
            projectId,
            sprintId,
            data: sprintData,
        };

        // Check if projectId already exist in state.selectedSprintPlan
        const indexToUpdate = state.selectedSprintPlan.findIndex(
            (item) => item.projectId === projectId,
        );

        if (indexToUpdate !== -1) {
        // If projectId already exist, update the existing item
            const updatedSelectedSprintPlan = [...state.selectedSprintPlan];
            updatedSelectedSprintPlan[indexToUpdate] = selectedSprintPlan;

            return state
                .set('isFetching', false)
                .set('selectedSprintPlan', updatedSelectedSprintPlan);
        }
        // If projectId do not exist, add the new item
        return state
            .set('isFetching', false)
            .set('selectedSprintPlan', [
                ...state.selectedSprintPlan,
                selectedSprintPlan,
            ])
            .set('sprintDatPlanPagination', pagination);
    }

    case UPDATE_DATA_SOURCE_SUCCESS: {
        const { status_update, parsedId } = payload;
        return state.set('isRefreshing', false).set('updatedProject', parsedId).set('statusUpdate', status_update);
    }
    case GET_BUG_SUMMARY_SUCCESS: {
        const { bug_summary } = payload;
        const {
            priorities,
            components,
            overall_bug_rate,
            time_used,
            overall_time_remaining,
            overall_original_estimate,
            bug_rate_tickets,
        } = bug_summary;
        const bugPriority = priorities.map((priority) => {
            const {
                id,
                icon_url,
                name,
                tickets_count,
                reopen_count,
                original_estimate,
                time_remaining,
                time_spent,
            } = priority;
            const originalEstimate = toHours(original_estimate);
            const timeRemaining = toHours(time_remaining);
            const timeSpent = toHours(time_spent);
            return {
                id,
                icon_url,
                name,
                ticketsCount: tickets_count,
                reopenCount: reopen_count,
                originalEstimate,
                timeRemaining,
                timeSpent,
            };
        });

        const bugComponent = components.map((component) => {
            const {
                id,
                name,
                high_priority,
                medium_priority,
                low_priority,
                reopen_count,
                original_estimate,
                time_remaining,
                time_spent,
                bug_rate,
                component_estimate,
            } = component;
            const originalEstimate = toHours(original_estimate);
            const timeRemaining = toHours(time_remaining);
            const timeSpent = toHours(time_spent);
            const componentEstimate = toHours(component_estimate);
            return {
                id,
                name,
                highPriority: high_priority,
                mediumPriority: medium_priority,
                lowPriority: low_priority,
                reopenCount: reopen_count,
                originalEstimate,
                timeRemaining,
                timeSpent,
                bugRate: bug_rate,
                component_estimate: componentEstimate,
            };
        });

        const bugTickets = bug_rate_tickets.map((ticket) => {
            const {
                type: ticketType,
                code,
                status,
                title,
                priority,
                total_original_estimate,
                total_time_remaining,
                total_time_spent,
                bug_rate,
                billable,
                non_billable,
                assignee,
                reporter,
            } = ticket;
            const originalEstimate = toHours(total_original_estimate);
            const timeRemaining = toHours(total_time_remaining);
            const timeSpent = toHours(total_time_spent);
            const timeBilled = toHours(billable);
            const nonBillable = toHours(non_billable);
            return {
                type: ticketType,
                code,
                status: status?.name,
                summary: title,
                priority,
                originalEstimate,
                timeRemaining,
                bug_rate: `${bug_rate}%`,
                timeSpent,
                timeBilled,
                nonBillable,
                assignee: assignee?.name,
                reporter: reporter?.name,
            };
        });
        return state.set('isFetching', false).set('bugSummary', {
            bugPriority,
            bugComponent,
            overallBugRate: overall_bug_rate,
            timeUsed: time_used,
            timeRemaining: overall_time_remaining,
            originalEstimate: overall_original_estimate,
            bugTicketBreakdown: bugTickets,
        });
    }
    case GET_BUGS_SUCCESS: {
        const { bugs } = payload;

        const bugTickets = bugs.map((ticket) => {
            const {
                type: ticketType,
                code,
                status,
                title,
                priority,
                components,
                original_estimate,
                time_remaining,
                time_log_time_spent,
                assignee,
                reporter,
            } = ticket;
            const originalEstimate = toHours(original_estimate);
            const timeRemaining = toHours(time_remaining);
            const timeSpent = toHours(time_log_time_spent);
            return {
                type: ticketType,
                code,
                status: status?.name,
                summary: title,
                priority,
                components,
                originalEstimate,
                timeRemaining,
                timeSpent,
                assignee: assignee?.name,
                reporter: reporter?.name,
            };
        });
        return state.set('isFetching', false).set('bugs', bugTickets);
    }
    case GET_BUG_SUMMARY_FAILURE:
    case GET_BUGS_FAILURE:
        return state
            .set('error', payload)
            .set('isFetching', false)
            .set('bugs', [])
            .set('bugSummary', []);

    case DELETE_BILLING_PERIOD_DATA_SUCCESS: {
        const { billingPeriodId } = payload;
        const updatedBillingPeriods = state.billingPeriods.filter(
            ({ id }) => id !== billingPeriodId,
        );
        return state
            .set('isUpdating', false)
            .set('isFetching', false)
            .set('billingPeriods', updatedBillingPeriods);
    }
    case GET_RETAINER_OVERVIEW_FAILURE:
    case RESET_RETAINER_OVERVIEW_FAILURE:
    case GET_TICKETS_FAILURE:
    case GET_SPRINT_DATA_FAILURE:
    case GET_BILLING_PERIOD_DATA_FAILURE:
    case GET_SPRINT_PLAN_DATA_FAILURE:
    case DELETE_BILLING_PERIOD_DATA_FAILURE:
        return state
            .set('error', payload)
            .set('isFetching', false)
            .set('isUpdating', false)
            .set('isTicketFetching', false);

    case UPDATE_PROJECT_FAILURE:
    case UPDATE_BILLING_PERIOD_DATA_FAILURE:
        return state.set('error', payload).set('isUpdating', false);
    case UPDATE_COMPONENTS_FAILURE:
    case UPDATE_BUDGETS_FAILURE:
        return state
            .set('error', payload)
            .set('isUpdating', false)
            .set('isFetching', false);

    case UPDATE_DATA_SOURCE_FAILURE:
        return state.set('error', payload).set('isRefreshing', false);

    case GET_PEV_INITIAL_VALUES_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case GET_PEV_INITIAL_VALUES_SUCCESS: {
        const { initialValues } = payload;
        return state
            .set('isFetching', false)
            .set('pevInitialValues', initialValues);
    }

    case GET_PEV_INITIAL_VALUES_FAILURE:
        return state.set('error', payload).set('isFetching', false);

    case GET_PEV_LATEST_SNAPSHOT_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case GET_PEV_LATEST_SNAPSHOT_SUCCESS: {
        const { snapshot } = payload;
        return state.set('isFetching', false).set('latestPevSnapshot', snapshot);
    }

    case GET_PEV_LATEST_SNAPSHOT_FAILURE:
        return state.set('error', payload).set('isFetching', false);

    case GET_NEW_PEV_SNAPSHOT_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case GET_NEW_PEV_SNAPSHOT_SUCCESS: {
        const { snapshot } = payload;
        return state.set('isFetching', false).set('latestPevSnapshot', snapshot);
    }

    case GET_NEW_PEV_SNAPSHOT_FAILURE:
        return state.set('error', payload).set('isRefreshing', false);

    case GET_BUG_TICKET_BREAKDOWN_REQUEST:
        return state
            .set('isFetching', true)
            .set('error', null)
            .set('bugTicketBreakdown', null);
    case RESET_BUG_SUMMARY_SUCCESS: {
        return state
            .set('isFetching', false)
            .set('bugSummary', {
                bugPriority: null,
                overallBugRate: null,
                timeUsed: null,
                timeRemaining: null,
                originalEstimate: null,
                bugTicketBreakdown: null,
            })
            .set('error', null);
    }

    case GET_BUG_TICKET_BREAKDOWN_SUCCESS: {
        const { bugs } = payload;
        const bugTickets = bugs.map((ticket) => {
            const {
                type: ticketType,
                code,
                status,
                title,
                priority,
                components,
                total_original_estimate,
                total_time_remaining,
                total_time_spent,
                bug_rate,
                billable,
                non_billable,
                assignee,
                reporter,
            } = ticket;
            const originalEstimate = toHours(total_original_estimate);
            const timeRemaining = toHours(total_time_remaining);
            const timeSpent = toHours(total_time_spent);
            const timeBilled = toHours(billable);
            const nonBillable = toHours(non_billable);
            return {
                type: ticketType,
                code,
                status: status?.name,
                summary: title,
                priority,
                components,
                originalEstimate,
                timeRemaining,
                bug_rate,
                timeSpent,
                timeBilled,
                nonBillable,
                assignee: assignee?.name,
                reporter: reporter?.name,
            };
        });
        return state
            .set('isFetching', false)
            .set('bugTicketBreakdown', bugTickets);
    }

    case GET_BUG_TICKET_BREAKDOWN_FAILURE:
        return state
            .set('error', payload)
            .set('isFetching', false)
            .set('bugTicketBreakdown', null);
    case RESET_BUG_SUMMARY_FAILURE:
        return state
            .set('error', payload)
            .set('isFetching', false)
            .set('isTicketFetching', false)
            .set('bugSummary', false);
    default:
        return state;
    }
}
