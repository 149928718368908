/* eslint-disable no-restricted-globals */
import { Form, Table as AntTable, Typography, Skeleton } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toString } from '../../core/utils/format';
import './editableTable.css';
import CellDataType, { CellDataTypes } from './CellDataType';

const formatters = {
    currency: (value) => new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP', maximumFractionDigits: 0 }).format(value),
    hours: (value) => new Intl.NumberFormat('en-UK', { style: 'decimal', minimumFractionDigits: 1 }).format(value),
    percent: (value) => new Intl.NumberFormat('en-UK', { style: 'percent', minimumFractionDigits: 1 }).format(value),
    // eslint-disable-next-line no-restricted-globals
    percentWithoutMultiply: (value) => (isNaN(value) ? value : `${value}%`),
    quarter: (value) => `Q${value}`,
    date: (value) => (value ? moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''),
};

const { Text } = Typography;

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    cellDataType,
    rules,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const form = useContext(EditableContext);
    const inputRef = useRef(null);

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        if (cellDataType === CellDataTypes.DATE_PICKER) {
            form.setFieldsValue({
                [dataIndex]: moment(record[dataIndex], 'DD/MM/YYYY'),

            });

        } else {
            form.setFieldsValue({
                [dataIndex]: record[dataIndex],
            });
        }
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();

            handleSave({
                ...record,
                ...values,
            });
            console.log('Save succesful:');

        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={rules}>
                <CellDataType ref={inputRef} cellDataType={cellDataType} save={save} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}>
                {childNode}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};

EditableRow.propTypes = {
    index: PropTypes.number,
};

EditableRow.defaultProps = {
    index: 0,
};

EditableCell.propTypes = {
    title: PropTypes.string.isRequired,
    editable: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    dataIndex: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    cellDataType: PropTypes.string,
    rules: PropTypes.array,
    handleSave: PropTypes.func.isRequired,
};

EditableCell.defaultProps = {
    cellDataType: null,
    rules: [],
};

const Table = ({ tableRef, columns, dataSource, setDataSource, loading, summary, expanded, resettable, onChangeCallback, title, totalShown }) => {
    const formatData = (data) => {
        const formatColumns = columns.filter((item) => item.format);

        const newData = data.map((row) => {
            const newRow = { ...row };
            formatColumns.forEach((col) => {
                newRow[col.dataIndex] = formatters[col.format](row[col.dataIndex]);
            });
            return newRow;
        });
        return newData;
    };

    const handleSave = (row) => {
        const tempRow = row;
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];

        Object.keys(tempRow).forEach((key) => {
            if (/^(\d{2})\/(\d{2})\/(\d{4})$/.test(tempRow[key])) {
                tempRow[key] = moment(tempRow[key], 'DD/MM/YYYY').format('YYYY-MM-DD');

            }
        });

        newData.splice(index, 1, {
            ...item,
            ...tempRow,
        });
        setDataSource(newData);
        onChangeCallback(newData);
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columnsMap = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                rules: col.rules,
                cellDataType: col.cellDataType,
                handleSave,
            }),
        };
    });

    return (
        <div>
            <AntTable
                title={title}
                ref={tableRef}
                className={expanded && 'expanded'}
                components={components}
                rowClassName={(record) => {
                    let classes = 'editable-row';
                    if (record?.summary === 'Uncategorised') {
                        classes += ' uncategorised-row';
                    }
                    return classes;
                }}
                pagination={{ pageSize: dataSource.length, position: ['none', 'none'] }}
                bordered
                loading={loading}
                locale={{
                    emptyText: loading ? <Skeleton active /> : null,
                }}
                dataSource={formatData(dataSource)}
                scroll={{ x: 'max-content', y: !expanded && 600 }}
                size="middle"
                onChange={resettable ? onChangeCallback : () => {}}
                summary={(currentData) => (
                    summary && (
                        <AntTable.Summary fixed>
                            <AntTable.Summary.Row>
                                { totalShown && (
                                    <AntTable.Summary.Cell index={0} align={summary?.titlePosition || 'center'}>
                                        <Text strong>{summary.title || 'Total'}</Text>
                                    </AntTable.Summary.Cell>
                                )}
                                { totalShown && (
                                    <AntTable.Summary.Cell colSpan={1} index={1}>
                                        <Text strong>{ !summary.hideTotal && currentData.filter(record => record?.code || record?.period)?.length }</Text>
                                    </AntTable.Summary.Cell>
                                )}

                                <AntTable.Summary.Cell colSpan={summary?.initialColSpan} />
                                { summary?.columns?.map(column => {
                                    let data = 0;
                                    if (column?.type === 'percent') {
                                        const percentages = currentData.map(record => {
                                            const val = (record[column?.key]) ? Number(record[column?.key].replace('%', '')) : 0;
                                            return !isNaN(val) ? val : null;
                                        }).filter(f => f);
                                        const sum = percentages.reduce((total, value) => total + value, 0);
                                        const average = sum / percentages.length;
                                        data = average;
                                    } else {
                                        data = currentData.reduce((acc, current) => {
                                            let total = acc;
                                            const currentEntry = current[column?.key];
                                            if (!isNaN(currentEntry)) {
                                                total += Number(currentEntry);
                                            }

                                            return total;
                                        }, 0);
                                    }
                                    return (
                                        <AntTable.Summary.Cell index={4} colSpan={1} align="center">
                                            <Text strong>{ column?.type === 'currency' && '£' }</Text>
                                            <Text strong>{ column?.default || toString(data) }</Text>
                                            <Text strong>{ column?.type === 'percent' && '%' }</Text>
                                        </AntTable.Summary.Cell>
                                    );
                                })}
                            </AntTable.Summary.Row>
                        </AntTable.Summary>
                    )
                )}
                columns={columnsMap} />
        </div>
    );
};

export default Table;

Table.propTypes = {
    tableRef: PropTypes.object,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
    setDataSource: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    summary: PropTypes.object,
    expanded: PropTypes.bool,
    resettable: PropTypes.bool,
    onChangeCallback: PropTypes.func,
    title: PropTypes.string,
    totalShown: PropTypes.bool,
};

Table.defaultProps = {
    tableRef: null,
    loading: false,
    summary: null,
    expanded: false,
    resettable: false,
    onChangeCallback: () => {},
    title: null,
    totalShown: true,
};
