import React from 'react';
import { Row, Col, Layout, Button } from 'antd';
import PropTypes from 'prop-types';

import Menu from '../Menu';
import scorchsoftLogo from '../../../assets/images/scorchsoft-logo-white.png';

import './header.css';

const Header = (props) => {
    const {
        menu, backLink, title, menuPlacement, menuWidth, floating,
        beforeHeader, afterHeader, showLogo, showDates, fetchedDate,
        refreshedDate, onLogout, statusUpdate,
    } = props;

    return (
        <div className={`header ${floating ? 'floating-header' : ''}`}>
            {beforeHeader}
            <Layout.Header className="header__inner">
                <Row type="flex" justify="space-between" align="middle" style={{ height: 'inherit' }}>
                    <Col>
                        {showLogo && (
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                <img src={scorchsoftLogo} alt="Scorchsoft Logo" className="scorchsoft-logo" />
                                <div className="header__seperator" />
                                <h3 className="header__logo_text h-4">Scorchsoft - Project Reporting</h3>
                            </div>
                        )}
                    </Col>
                    <Col>{backLink}
                        {menu.length > 0 ? <Menu items={menu} placement={menuPlacement} width={menuWidth} /> : null }
                    </Col>
                    <Col>
                        <Row type="flex" gutter={20} align="middle">
                            <div style={{ marginRight: 40, display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center' }}>
                                {showDates && (
                                    <Col span={24} style={{ marginRight: 20 }}>
                                        <Row gutter={10}>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                                <Col><p className="header__text header__text_header">Last Refreshed</p></Col>
                                                <Col><p className="header__text">{refreshedDate}</p></Col>
                                            </div>
                                        </Row>
                                        <Row gutter={10}>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                                <Col className="header__text header__text_header">Last Fetched</Col>
                                                <Col className="header__text">{ fetchedDate }</Col>
                                            </div>
                                        </Row>
                                        <Row gutter={10}>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                                <Col className="header__text header__text_header">Status</Col>
                                                <Col className="header__text">{ statusUpdate }</Col>
                                            </div>
                                        </Row>
                                    </Col>
                                )}
                                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center' }}>
                                    <Col className="header__title h-4">{title}</Col>
                                    <div className="header__seperator" />
                                    <Button type="text" className="header__title h-4" onClick={onLogout}>Log out</Button>
                                </div>
                            </div>
                        </Row>
                    </Col>

                    {/*
                    // uncomment this if you want to use toolbar
                    <Col>
                        <div className="d-flex justify-content-end">
                            {toolbar.map((button, buttonIndex) => (
                                <Button
                                    key={button.id || buttonIndex}
                                    size={button.size || 'default'}
                                    className="toolbar-button"
                                    href={button.link || '#'}
                                    onClick={button.onClick || null}>

                                    <span>{button.label}</span>
                                </Button>
                            ))}
                        </div>
                    </Col> */
                    }
                    {/* <Col>
                        <Dropdown arrow overlay={menu} trigger={['click']} placement="bottomRight">
                            <div>
                                <Avatar className="header__avatar" icon={<UserIcon />} />
                                <DownOutlined />
                            </div>
                        </Dropdown>

                    </Col> */}
                </Row>
            </Layout.Header>
            {afterHeader}
        </div>
    );
};

Header.defaultProps = {
    toolbar: [],
    menu: [],
    backLink: null,
    logo: null,
    menuPlacement: 'left',
    menuWidth: 256,
    floating: false,
    beforeHeader: null,
    afterHeader: null,
    title: null,
    showLogo: true,
    showDates: false,
    fetchedDate: 'N/A',
    refreshedDate: 'N/A',
    statusUpdate: 'N/A',
    onLogout: null,
};

Header.propTypes = {
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    toolbar: PropTypes.array,
    menu: PropTypes.array,
    backLink: PropTypes.object,
    logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    menuPlacement: PropTypes.string,
    menuWidth: PropTypes.number,
    floating: PropTypes.bool,
    beforeHeader: PropTypes.object,
    afterHeader: PropTypes.object,
    showLogo: PropTypes.bool,
    showDates: PropTypes.bool,
    fetchedDate: PropTypes.string,
    refreshedDate: PropTypes.string,
    statusUpdate: PropTypes.string,
    onLogout: PropTypes.func,
};

export default Header;
