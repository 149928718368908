import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination, Spin } from 'antd';

import PropTypes from 'prop-types';
import CoreTable from '../../components/CoreTable/CoreTable';
import { TYPES } from '../../components/CoreTable/config';

import './issueTable.css';
import { getTicketsRequest } from '../../core/agileProject/agileProjectActions';
import ObjectFilters from '../../components/EditableTable/filters/ObjectFilters';

const IssueTable = () => {
    const projectData = useSelector((state) => state?.agileProject);
    const { ticketsPagination, tickets = [], isTicketFetching, filters: filterOptions } = useSelector((state) => state.agileProject);
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [filterInfo, setFilterInfo] = useState(null);
    const [sorterInfo, setSorterInfo] = useState(null);
    const [filetedData, setFilteredData] = useState(tickets);
    const [filters, setFilters] = useState(null);

    const settings = projectData?.settings;

    const handleChangePage = (pageNumber) => {
        setPage(pageNumber);
    };
    const handleChangePageSize = (current, size) => {
        setPageSize(size);

    };
    const handleFilterChange = (filter) => {
        setFilterInfo(filter);
    };

    const handleSorterChange = (sorter) => {
        setSorterInfo(sorter);
    };

    useEffect(() => {
        setFilters(null);

        if (filterOptions) {
            const initialFilters = {
                type: ObjectFilters('type', filterOptions),
                code: ObjectFilters('code', filterOptions),
                storyCode: ObjectFilters('storyCode', filterOptions),
                sprints: ObjectFilters('sprints', filterOptions),
                status: ObjectFilters('status', filterOptions),
                fixVersions: ObjectFilters('fixVersions', filterOptions),
                components: ObjectFilters('components', filterOptions),

            };
            setFilters(initialFilters);
        }
    }, [filterOptions]);

    useEffect(() => {
        setFilteredData(tickets);
    }, [tickets]);

    useEffect(() => {
        dispatch(getTicketsRequest({ projectCode: settings.projectCode, projectName: settings.projectName, pageSize, filters: filterInfo, sorters: sorterInfo }));
    }, [filterInfo, sorterInfo, pageSize]);

    useEffect(() => {
        dispatch(getTicketsRequest({ projectCode: settings.projectCode, projectName: settings.projectName, pageSize, page, filters: filterInfo, sorters: sorterInfo }));
    }, [page]);

    // page size is A1 since this table is massive.
    const printStyles = `@media print {
        @page {
            size: 841mm 594mm;
            margin: 0mm;
        }
        body {
            background-color: #ffffff !important;
        }
        .ant-table .ant-table-container::before,
        .ant-table .ant-table-container::after {
            width: 0px !important;
        }
        .printable {
            display: block !important;
        }
        .printable-content {
            padding-top: 10mm;
            padding-right: 20mm;
            padding-left: 20mm;
            padding-bottom: 10mm;
            background-color: #ffffff !important;
        }
    }`;

    const showTotal = (total) => `Results: ${ticketsPagination.from} - ${ticketsPagination.to} of ${total}`;

    return (
        <div style={{ margin: 20 }}>

            <div className="sm-seperator" />
            {
                isTicketFetching && (
                    <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                        <Spin size="large" />
                    </div>
                )
            }
            {
                !isTicketFetching && settings && (
                    <>
                        <CoreTable
                            type={TYPES.ISSUE_BREAKDOWN}
                            dataSource={filetedData}
                            isFetching={isTicketFetching}
                            printable
                            printStyles={printStyles}
                            projectName={settings.projectName}
                            clientName={settings.clientName}
                            filterInfo={filterInfo}
                            handleFilterChange={handleFilterChange}
                            filterValue={filters}
                            sorterInfo={sorterInfo}
                            handleSorterChange={handleSorterChange} />

                        <div className="sm-seperator" />

                        <div className="custom-pagination-container">

                            { tickets.length > 0 && (
                                <Pagination
                                    showSizeChanger
                                    disabled={isTicketFetching}
                                    pageSizeOptions={['10', '50', '100', '500', '1000']}
                                    current={ticketsPagination.current}
                                    total={ticketsPagination.total}
                                    pageSize={ticketsPagination.page_size}
                                    showTotal={showTotal}
                                    onChange={handleChangePage}
                                    onShowSizeChange={handleChangePageSize} />
                            )}

                        </div>
                    </>
                )
            }
        </div>
    );
};

IssueTable.propTypes = {
    project: PropTypes.string,
};

IssueTable.defaultProps = {
    project: null,
};

export default IssueTable;
