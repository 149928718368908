import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import { Button, Form, Input, Modal, Space } from 'antd';
import Password from 'antd/lib/input/Password';
import useUser from '../../core/user/useUser';
import history from '../../core/utils/history';

import logoSrc from '../../assets/images/scorchsoft-logo-white.png';
import icon from '../../assets/favicon.ico';
import './login.scss';

const { APP_NAME } = require('../../core/constants').default;

const LoginPage = () => {
    const user = useSelector(state => state.user);
    const { isFetching } = user || false;
    const [form] = useForm();
    const [modalOpen, setModalOpen] = useState(false);

    const { loginRequest, forgotPasswordRequest, user: currentUser } = useUser();

    const onSubmit = (data) => {
        loginRequest(data);
    };

    useEffect(() => {
        if (currentUser?.isLoggedIn === true) {
            history.push('/dashboard');
        } else {
            history.push('/');
        }
    }, [currentUser?.isLoggedIn]);

    const handleForgotFinish = (data) => {
        forgotPasswordRequest({
            email: data?.email,
        });
        setTimeout(() => { setModalOpen(false); }, 1500);
    };

    const forgotFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    return (
        <div className="login-wrap">
            <Helmet
                title={`${APP_NAME}`}
                titleTemplate={`${APP_NAME}`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: `${APP_NAME} website` },
                    { property: 'og:type', content: 'website' },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            <div className="login-info">
                <div className="login-info__text">
                    <img src={logoSrc} alt="logo" className="main-logo" />
                </div>
            </div>
            <div className="login-form-box">
                <Form
                    name="login"
                    form={form}
                    className="form"
                    layout="vertical"
                    onFinishFailed={onFinishFailed}
                    onFinish={(data) => onSubmit(data)}>
                    <h2 className="form-title">Login</h2>
                    <Form.Item label="Email Address" rules={[{ required: true, message: 'Username cannot be empty!' }, { required: true, type: 'email', message: 'This must be a valid email address!' }]} name="email">
                        <Input placeholder="Enter your email address" name="email" />
                    </Form.Item>
                    <Form.Item label="Password" rules={[{ required: true, message: 'Password cannot be empty!' }]} name="password">
                        <Password placeholder="Enter your password" name="password" />
                    </Form.Item>
                    <Button
                        type="primary"
                        loading={isFetching}
                        size="large"
                        className="login-form-button mt-3"
                        block
                        onClick={async () => {
                            try {
                                await form.validateFields();
                                form.submit();
                            } catch (e) {
                                // Do nothing since antd handles it.
                            }
                        }}>
                        Login
                    </Button>
                    <div className="text-center">
                        <Button onClick={() => setModalOpen(true)} type="link" className="button-link mt-2" href="#" htmlType="submit">
                            Forgotten password?
                        </Button>
                    </div>
                </Form>
            </div>
            <Modal centered width={540} footer={null} open={modalOpen} onOk={() => { setModalOpen(false); }} onCancel={() => { setModalOpen(false); }}>
                <h4 className="h-3 title-text-md  text-center">Forgot password</h4>
                <div className="form-wrap">
                    <Form onFinish={handleForgotFinish} layout="vertical" onFinishFailed={forgotFinishFailed}>
                        <Form.Item label="Email" name="email" rules={[{ type: 'email', message: 'The input is not valid E-mail!' }, { required: true, message: 'Please input your E-mail!' }]}>
                            <Input />
                        </Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit" className="wide">
                                Reset my password
                            </Button>
                            <Button className="link-green btn-empty underlined" onClick={() => { setModalOpen(false); }}>
                                Cancel
                            </Button>
                        </Space>
                    </Form>
                </div>
            </Modal>
        </div>
    );
};

export default LoginPage;
