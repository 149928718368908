/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Button, Dropdown, Space, Spin } from 'antd';
import { filter, isNull, map, uniq } from 'underscore';
import { capitalize } from 'underscore.string';
import { DownOutlined } from '@ant-design/icons';
import useUser from '../../core/user/useUser';
import Main from '../../components/layout/Main';

import './dashboard.scss';
import Card from '../../components/Card/Card';
import { getDashProjectsRequest, setDashboardUrl } from '../../core/dashboard/dashboardActions';
import { getAllUserRequest } from '../../core/user/userActions';

const statusDropdownOptions = [
    {
        label: 'Active',
        key: 'active',
    },
    {
        label: 'Inactive',
        key: 'inactive',
    },
];

const typeDropdownOptions = [
    {
        label: 'Sprint',
        key: 'sprint',
    },
    {
        label: 'Waterfall',
        key: 'waterfall',
    },
    {
        label: 'Retainer',
        key: 'retainer',
    },
];

const Dashboard = () => {
    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const { projectData } = useSelector((state) => state?.dashboard) || {};
    const { userData, users } = useSelector((state) => state?.user || {});
    const [project, setProject] = useState([]);
    const dispatch = useDispatch();
    const { logoutRequest } = useUser();

    const location = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);
    const [pmFilter, setPmFilter] = useState(searchParams.get('pm'));
    const [ldFilter, setLdFilter] = useState(searchParams.get('ld'));
    const [typeFilter, setTypeFilter] = useState(searchParams.get('type'));
    const [statusFilter, setStatusFilter] = useState(searchParams.get('status'));
    const currentYear = new Date().getFullYear();
    const pmDropdownData = useMemo(() => {
        const pms = map(project, p => ({ label: p?.project_manager?.name, key: p?.project_manager?.name }));
        const unique = uniq(pms, false, p => p.label);
        return filter(unique, u => u.label && u.key);
    }, [project]);

    const ldDropdownData = useMemo(() => {
        const lds = map(project, p => ({ label: p?.lead_dev?.name, key: p?.lead_dev?.name }));
        const unique = uniq(lds, false, l => l.label);
        return filter(unique, u => u.label && u.key);
    }, [project]);

    const filterConfig = useMemo(() => {
        const config = [{
            label: 'Project Manager',
            key: 'pm',
            data: pmDropdownData,
            state: pmFilter,
            setState: (val) => setPmFilter(val),
        },
        {
            label: 'Lead Developer',
            key: 'ld',
            data: ldDropdownData,
            state: ldFilter,
            setState: (val) => setLdFilter(val),
        },
        {
            label: 'Type',
            key: 'type',
            data: typeDropdownOptions,
            state: typeFilter,
            setState: (val) => setTypeFilter(val),
        },
        {
            label: 'Status',
            key: 'status',
            data: statusDropdownOptions,
            state: statusFilter,
            setState: (val) => setStatusFilter(val),
        }];

        return config;
    }, [pmDropdownData, ldDropdownData, pmFilter, ldFilter, typeFilter, statusFilter]);

    const addOrUpdateParam = (key, value) => {
        searchParams.set(key, value);
        history.push({ search: searchParams.toString() });
        dispatch(setDashboardUrl(searchParams.toString()));
    };

    const resetFilters = () => {
        setLdFilter(null);
        setPmFilter(null);
        setTypeFilter(null);
        setStatusFilter(null);

        searchParams.delete('pm');
        searchParams.delete('ld');
        searchParams.delete('type');
        searchParams.delete('status');

        history.push({ search: searchParams.toString() });
        dispatch(setDashboardUrl(searchParams.toString()));
    };

    const filteredProjects = useMemo(() => (
        filter(project, p => {
            const pmCondition = pmFilter ? (p?.project_manager?.name === pmFilter) : true;
            const ldCondition = ldFilter ? (p?.lead_dev?.name === ldFilter) : true;

            const typeConditionCalc = () => {
                let condition = true;
                if (!isNull(typeFilter)) {
                    if ((p.agile && typeFilter === 'sprint' && p.type === 30) || (!p.agile && typeFilter === 'waterfall') || (p.agile && typeFilter === 'retainer' && p.type === 20)) {
                        condition = true;
                    } else {
                        condition = false;
                    }
                }

                return condition;
            };
            const typeCondition = typeConditionCalc();

            const statusConditionCalc = () => {
                let condition = true;
                if (!isNull(statusFilter)) {
                    if ((p.status === true && statusFilter === 'active') || (p.status === false && statusFilter === 'inactive')) {
                        condition = true;
                    } else {
                        condition = false;
                    }
                }

                return condition;
            };

            const statusCondition = statusConditionCalc();
            return (pmCondition && ldCondition && typeCondition && statusCondition);
        })
    ), [project, pmFilter, ldFilter, typeFilter, statusFilter]);

    useEffect(() => {
        dispatch(getDashProjectsRequest());
        dispatch(getAllUserRequest());
        setStatusFilter('active');
        const isLeadDeveloper = users?.lead_developer?.find((developer) => developer.id === userData.id);
        const isProjectTeam = users?.project_team?.find((proj) => proj.id === userData.id);
        if (isProjectTeam) {
            setPmFilter(userData.name);
        } else if (isLeadDeveloper) {
            setLdFilter(userData.name);
        }
        history.push({ search: searchParams.toString() });
    }, []);

    useEffect(() => {
        if (projectData) {
            setProject(projectData);
        }
    }, [projectData]);

    const renderProjectCard = (data) => (
        <Card projects={data} />
    );

    const handleLogout = () => {
        logoutRequest();
    };

    return (
        <Main
            className="login"
            title={<div>Welcome</div>}
            headerTitle={<div>Dashboard</div>}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; Scorchsoft Ltd {currentYear} </div>}
            floatingFooter
            onLogout={handleLogout}>
            <div style={{ marginInline: '50px' }}>
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <h1>Dashboard</h1>
                </div>
                <div style={{ flexDirection: 'row', marginBottom: 40 }}>
                    <Button style={{ marginRight: 10 }} type="primary" onClick={() => resetFilters()}>
                        <Space>
                            Reset filters
                        </Space>
                    </Button>
                    { filterConfig ? (
                        filterConfig.map(f => {
                            if (f?.data?.length > 0) {
                                return (
                                    <Dropdown
                                        overlayClassName="dropdown"
                                        overlayStyle={{ width: 200, marginRight: 10, maxHeight: 300, overflowY: 'auto' }}
                                        menu={{
                                            items: f.data,
                                            onClick: ({ key }) => {
                                                f.setState(key);
                                                addOrUpdateParam(f.key, key);
                                            },
                                            selectable: true,
                                        }}>
                                        <Button style={{ width: 200, marginRight: 10, color: (f?.state) ? 'black' : '#a7a7a7' }}>
                                            <Space style={{ justifyContent: 'space-between', width: 170 }}>
                                                {f?.state ? capitalize(f?.state) : f.label}
                                                <DownOutlined />
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                );
                            }
                            return null;
                        })
                    ) : null }
                    <Button style={{ marginRight: 10 }} type="primary">
                        <Link to="/report/create">
                            <Space>
                                Create Project
                            </Space>
                        </Link>
                    </Button>
                </div>
                <div style={{ marginBottom: '100px' }}>
                    {!projectData && (<Spin style={{ margin: 10 }} size="large" />)}
                    {projectData && renderProjectCard(filteredProjects)}
                </div>
            </div>
        </Main>
    );
};

export default Dashboard;

Dashboard.propTypes = {};
