import React from 'react';
import PropTypes from 'prop-types';

import { AuthRoute, CoreRouter, DefaultRoute } from './utils/coreRouter';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import ProgrammeOverview from '../pages/ProgrammeOverview';
import MQProductivityBreakdown from '../pages/MQProductivityBreakdown';
import MQAccuracyReview from '../pages/MQAccuracyReview';
import ProjectSettings from '../pages/ProjectSettings';
import Overview from '../pages/Overview';
import Report from '../pages/Report';

const Routes = () => (
    <CoreRouter>
        <DefaultRoute exact path="/" component={Login} />
        <AuthRoute exact path="/dashboard" component={Dashboard} />
        <AuthRoute exact path="/programme-overview" component={ProgrammeOverview} />
        <AuthRoute exact path="/m-q-productivity-breakdown" component={MQProductivityBreakdown} />
        <AuthRoute exact path="/m-q-accuracy-review" component={MQAccuracyReview} />
        <AuthRoute exact path="/report/:projectCode/:projectName" component={ProjectSettings} />
        <AuthRoute exact path="/report/create" component={ProjectSettings} />
        <AuthRoute exact path="/overview" component={Overview} />
        <AuthRoute exact path="/report/:id" component={Report} />
        {/* <DefaultRoute exact path="/reset-password/:token/:email" component={ResetPassword} /> */}
        {/* <AuthRoute exact path="/auth/dashboard" component={Dashboard} /> */}
    </CoreRouter>
);

Routes.defaultProps = {
    persistor: null,
};

Routes.propTypes = {
    persistor: PropTypes.object,
};

export default Routes;
