import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchApiAuth } from '../utils/api';
import {
    getDashProjectsSuccess,
    getDashProjectsFailure,
} from './dashboardActions';

const {
    GET_DASH_PROJECTS_REQUEST,
} = require('./dashboardActions').constraints;

function* getProjects() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/projects?managers=true',
        });
        yield put(getDashProjectsSuccess(response?.data));
    } catch (e) {
        yield put(getDashProjectsFailure(e.response ? e.response.data.message : e));
    }
}

export default function* dashboardSagas() {
    yield* [
        takeEvery(GET_DASH_PROJECTS_REQUEST, getProjects),
    ];
}
