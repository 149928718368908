import { Record } from 'immutable';

const {
    GET_DASH_PROJECTS_REQUEST,
    GET_DASH_PROJECTS_SUCCESS,
    GET_DASH_PROJECTS_FAILURE,

    SET_DASHBOARD_URL,
} = require('./dashboardActions').constraints;

const InitialState = Record({
    isFetching: false,
    error: null,
    projectData: [],
    dashboardUrl: null,
});

const initialState = new InitialState();

export default function dashboardReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case GET_DASH_PROJECTS_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case GET_DASH_PROJECTS_SUCCESS:
        return state.set('isFetching', false).set('projectData', payload?.projects);

    case GET_DASH_PROJECTS_FAILURE:
        return state.set('error', payload).set('isFetching', false);

    case SET_DASHBOARD_URL:
        return state.set('dashboardUrl', payload);
    default:
        return state;
    }
}
