const view = [
    { view: 'overview' },
];

const type = [
    { sprint: 30 },
    { retainer: 20 },
    { fixed: 10 },
];
export default view;

export { type };
