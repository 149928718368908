import React from 'react';
import { Input, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import DatePicker from '../PickerComponent/DatePicker';

export const CellDataTypes = {
    DATE_PICKER: 'date-picker',
    INPUT: 'input',
    NUMBER: 'number',
};

const CellDataType = React.forwardRef(({ disabled, cellDataType, save, ...restProps }, ref) => {
    switch (cellDataType) {
    case CellDataTypes.DATE_PICKER:
        return (
            <DatePicker
                ref={ref}
                disabled={disabled}
                format="DD/MM/YYYY"
                onChange={save}
                onBlur={save}
                {...restProps} />
        );
    case CellDataTypes.NUMBER:
        return <InputNumber ref={ref} disabled={disabled} onPressEnter={save} onBlur={save} {...restProps} />;
    default:
        return <Input ref={ref} disabled={disabled} onPressEnter={save} onBlur={save} {...restProps} />;
    }
});

CellDataType.propTypes = {
    disabled: PropTypes.bool,
    ref: PropTypes.shape({ current: PropTypes.instanceOf(CellDataType) }).isRequired,
    cellDataType: PropTypes.oneOf([
        CellDataTypes.DATE_PICKER,
        CellDataTypes.INPUT,
        CellDataTypes.NUMBER,
    ]),
    save: PropTypes.func,
};

CellDataType.defaultProps = {
    disabled: false,
    cellDataType: null,
    save: () => null,
};

export default CellDataType;
