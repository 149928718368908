import React from 'react';
import moment from 'moment';
import { toHours } from '../../core/utils/time';

const renderImage = (data) => {
    if (data) {
        return (
            <img
                style={{ width: '25px', height: '25px' }}
                src={data?.icon_url}
                alt={data?.name} />
        );
    }

    return '';
};

const renderList = (data) => {
    if (data) {
        const names = data?.map(d => d?.name);
        return names.join(', ');
    }

    return '';
};

const renderLink = (data) => (
    <a href={`https://scorchsoft.atlassian.net/browse/${data}`}>
        { data }
    </a>
);

const renderHours = (data) => {
    if (data) {
        return toHours(data);
    }

    return 0;
};

const renderPeriod = (data) => {
    if (moment(data, true).isValid()) {
        return moment(data).format('DD/MM/YYYY');
    }
    if (moment(data, 'YYYY-MM-DD', true).isValid()) {
        return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
    return data;
};

export { renderImage, renderList, renderLink, renderHours, renderPeriod };
