import React from 'react';
import MinMaxFilter from '../EditableTable/filters/MinMaxFilter';
import NestedSelectionFilter from '../EditableTable/filters/NestedSelectionFilter';
import SearchFilter from '../EditableTable/filters/SearchFilter';
import SelectionFilter from '../EditableTable/filters/SelectionFilter';
import ObjectFilter from '../EditableTable/filters/ObjectFilter';
import { CleanSorter, PercentSorter, SimpleSorter } from '../EditableTable/sorters';
import { renderImage, renderLink, renderList } from './CellRenderers';
import CustomFilterDropdown from './CustomFilterDropdown';

export const TYPES = {
    ISSUE_BREAKDOWN: 'issue-breakdown',
    SPRINT_BREAKDOWN: 'sprint-breakdown',
    BILLING_PERIOD_BREAKDOWN: 'billing-period-breakdown',
    SPRINT_PLAN: 'sprint-plan',
    BUG_RATE_ANALYSIS: 'bug-rate-analysis',
    BUG_TICKET_BY_PRIORITY: 'bug-ticket-by-priority',
    WATERFAL_BUG_COMPONENT: 'waterfall-bug-component',
    WATERFALL_BUG_RATE_ANALAYSIS: 'waterfall-bug-rate-analysis',
    ESTIMATE_PEV_DATA_TABLE: 'estimate-pev-data-table',
    TOTAL_TIME_SPENT_PEV_DATA_TABLE: 'total-time-spent-pev-data-table',
    OVERVIEW_PEV_DATA_TABLE: 'overview-pev-data-table',
    COMPARISON_PEV_DATA_TABLE: 'comparison-pev-data-table',
};

const COL_WIDTH_TINY = 75;
const COL_WIDTH_XSM = 100;
const COL_WIDTH_MID = 200;
const COL_WIDTH_LRG = 250;
const COL_WIDTH_SM = 150;
const COL_WIDTH_LSM = 170;

const getConfig = (type, dataSource, filterInfo, sorterInfo, filterValue) => {

    const config = [
        {
            key: TYPES.ISSUE_BREAKDOWN,
            typeName: 'Issue Breakdown',
            columns: [
                {
                    title: 'Type',
                    dataIndex: 'type',
                    key: 'type',
                    align: 'center',
                    width: COL_WIDTH_TINY,
                    fixed: 'left',
                    filterMode: filterValue?.type?.filterMode,
                    filters: filterValue?.type?.filters,
                    render: (data) => renderImage(data),
                    filteredValue: filterInfo?.type || null,
                    filterSearch: true,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    key: 'code',
                    align: 'left',
                    width: COL_WIDTH_XSM,
                    fixed: 'left',
                    render: (data) => renderLink(data),
                    filterMode: filterValue?.code?.filterMode,
                    filters: filterValue?.code?.filters,
                    filteredValue: filterInfo?.code || null,
                    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                        <CustomFilterDropdown
                            setSelectedKeys={setSelectedKeys}
                            selectedKeys={selectedKeys}
                            confirm={confirm}
                            filters={filterValue?.code?.filters} />
                    ),
                    onFilter: (value, record) => value.includes(record.code),
                },
                {
                    title: 'Story Code',
                    dataIndex: 'storyCode',
                    key: 'storyCode',
                    align: 'left',
                    width: COL_WIDTH_XSM,
                    render: (data) => renderLink(data),
                    filterSearch: (input, record) => record.text && record.text.toLowerCase().includes(input.toLowerCase()),
                    filterMode: filterValue?.storyCode?.filterMode,
                    filters: filterValue?.storyCode?.filters,
                    filteredValue: filterInfo?.storyCode || null,
                },
                {
                    title: 'Sprints',
                    dataIndex: 'sprints',
                    key: 'sprints',
                    align: 'left',
                    width: COL_WIDTH_SM,
                    render: (data) => renderList(data),
                    filterMode: filterValue?.sprints?.filterMode,
                    filters: filterValue?.sprints?.filters,
                    filteredValue: filterInfo?.sprints || null,
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    align: 'left',
                    width: COL_WIDTH_XSM,
                    filterMode: filterValue?.status?.filterMode,
                    filters: filterValue?.status?.filters,
                    filteredValue: filterInfo?.status || null,
                },
                {
                    title: 'Summary',
                    dataIndex: 'summary',
                    key: 'summary',
                    align: 'left',
                    width: COL_WIDTH_LRG,
                    ...SearchFilter('summary', 'Summary'),
                    filteredValue: filterInfo?.summary || null,
                },
                {
                    title: 'Priority',
                    dataIndex: 'priority',
                    key: 'priority',
                    align: 'center',
                    width: COL_WIDTH_TINY,
                    render: (data, record) => renderImage(data, record),
                    filteredValue: filterInfo?.priority || null,
                },
                {
                    title: 'Components',
                    dataIndex: 'components',
                    key: 'components',
                    align: 'left',
                    width: COL_WIDTH_SM,
                    render: (data) => renderList(data),
                    filterMode: filterValue?.components?.filterMode,
                    filters: filterValue?.components?.filters,
                    filteredValue: filterInfo?.components || null,
                },
                {
                    title: 'Fix Versions',
                    dataIndex: 'fixVersions',
                    key: 'fixVersions',
                    align: 'left',
                    width: COL_WIDTH_XSM,
                    render: (data) => renderList(data),
                    filterMode: filterValue?.fixVersions?.filterMode,
                    filters: filterValue?.fixVersions?.filters,
                    filteredValue: filterInfo?.fixVersions || null,
                },
                {
                    title: 'Pessimictic',
                    dataIndex: 'pessimistic_estimate',
                    key: 'pessimistic_estimate',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    sorter: (a, b) => SimpleSorter(a, b, 'pessimistic_estimate'),
                    ...MinMaxFilter('pessimistic_estimate'),
                    filteredValue: filterInfo?.pessimistic_estimate || null,
                    sortOrder: sorterInfo?.columnKey === 'pessimistic_estimate' ? sorterInfo.order : null,
                },
                {
                    title: 'Optimistic',
                    dataIndex: 'optimistic_estimate',
                    key: 'optimistic_estimate',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    sorter: (a, b) => SimpleSorter(a, b, 'optimistic_estimate'),
                    ...MinMaxFilter('optimistic_estimate'),
                    filteredValue: filterInfo?.optimistic_estimate || null,
                    sortOrder: sorterInfo?.columnKey === 'optimistic_estimate' ? sorterInfo.order : null,
                },
                {
                    title: 'Most Likely Estimate',
                    dataIndex: 'quote_time',
                    key: 'quote_time',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    sorter: (a, b) => SimpleSorter(a, b, 'quote_time'),
                    ...MinMaxFilter('quote_time'),
                    filteredValue: filterInfo?.quote_time || null,
                    sortOrder: sorterInfo?.columnKey === 'quote_time' ? sorterInfo.order : null,
                },
                {
                    title: 'Original Estimate',
                    dataIndex: 'original_estimate',
                    key: 'original_estimate',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    sorter: (a, b) => SimpleSorter(a, b, 'original_estimate'),
                    ...MinMaxFilter('original_estimate'),
                    filteredValue: filterInfo?.original_estimate || null,
                    sortOrder: sorterInfo?.columnKey === 'original_estimate' ? sorterInfo.order : null,
                },
                {
                    title: 'Time Billed',
                    dataIndex: 'time_spent',
                    key: 'time_spent',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    sorter: (a, b) => SimpleSorter(a, b, 'time_spent'),
                    ...MinMaxFilter('time_spent'),
                    filteredValue: filterInfo?.time_spent || null,
                    sortOrder: sorterInfo?.columnKey === 'time_spent' ? sorterInfo.order : null,
                },
                {
                    title: 'Time Remaining',
                    dataIndex: 'time_remaining',
                    key: 'time_remaining',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    sorter: (a, b) => SimpleSorter(a, b, 'time_remaining'),
                    ...MinMaxFilter('time_remaining'),
                    filteredValue: filterInfo?.time_remaining || null,
                    sortOrder: sorterInfo?.columnKey === 'time_remaining' ? sorterInfo.order : null,
                },
                {
                    title: 'Total Est Time',
                    dataIndex: 'total_estimate_time',
                    key: 'total_estimate_time',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    sorter: (a, b) => SimpleSorter(a, b, 'total_estimate_time'),
                    ...MinMaxFilter('total_estimate_time'),
                    filteredValue: filterInfo?.total_estimate_time || null,
                    sortOrder: sorterInfo?.columnKey === 'total_estimate_time' ? sorterInfo.order : null,
                },
                {
                    title: 'Unforeseen Time',
                    dataIndex: 'unforeseen_time',
                    key: 'unforeseen_time',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    sorter: (a, b) => SimpleSorter(a, b, 'unforeseen_time'),
                    ...MinMaxFilter('unforeseen_time'),
                    filteredValue: filterInfo?.unforeseen_time || null,
                    sortOrder: sorterInfo?.columnKey === 'unforeseen_time' ? sorterInfo.order : null,
                },
                {
                    title: 'Not Billed Time',
                    dataIndex: 'nonbillable',
                    key: 'nonbillable',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    sorter: (a, b) => SimpleSorter(a, b, 'nonbillable'),
                    ...MinMaxFilter('nonbillable'),
                    filteredValue: filterInfo?.nonbillable || null,
                    sortOrder: sorterInfo?.columnKey === 'nonbillable' ? sorterInfo.order : null,
                },
                {
                    title: 'Δ Quoted Est',
                    dataIndex: 'quoted_estimate_delta',
                    key: 'quoted_estimate_delta',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                    sorter: (a, b) => CleanSorter(a, b, 'quoted_estimate_delta'),
                    ...MinMaxFilter('quoted_estimate_delta'),
                    filteredValue: filterInfo?.quoted_estimate_delta || null,
                    sortOrder: sorterInfo?.columnKey === 'quoted_estimate_delta' ? sorterInfo.order : null,
                },
                {
                    title: 'Δ% Quoted Est',
                    dataIndex: 'quoted_estimate_delta_perc',
                    key: 'quoted_estimate_delta_perc',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                    format: 'percentWithoutMultiply',
                    sorter: (a, b) => PercentSorter(a, b, 'quoted_estimate_delta_perc'),
                    ...MinMaxFilter('quoted_estimate_delta_perc'),
                    filteredValue: filterInfo?.quoted_estimate_delta_perc || null,
                    sortOrder: sorterInfo?.columnKey === 'quoted_estimate_delta_perc' ? sorterInfo.order : null,
                },
                {
                    title: 'Δ Final Est',
                    dataIndex: 'final_estimate_delta',
                    key: 'final_estimate_delta',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                    sorter: (a, b) => CleanSorter(a, b, 'final_estimate_delta'),
                    ...MinMaxFilter('final_estimate_delta'),
                    filteredValue: filterInfo?.final_estimate_delta || null,
                    sortOrder: sorterInfo?.columnKey === 'final_estimate_delta' ? sorterInfo.order : null,
                },
                {
                    title: 'Δ% Final Est',
                    dataIndex: 'final_estimate_delta_perc',
                    key: 'final_estimate_delta_perc',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                    format: 'percentWithoutMultiply',
                    sorter: (a, b) => PercentSorter(a, b, 'final_estimate_delta_perc'),
                    ...MinMaxFilter('final_estimate_delta_perc'),
                    filteredValue: filterInfo?.final_estimate_delta_perc || null,
                    sortOrder: sorterInfo?.columnKey === 'final_estimate_delta_perc' ? sorterInfo.order : null,
                },
            ],
            summaryConfig: {
                initialColSpan: 0,
                columns: [
                    { key: 'storyCode', default: ' ' },
                    { key: 'sprints', default: ' ' },
                    { key: 'status', default: ' ' },
                    { key: 'summary', default: ' ' },
                    { key: 'priority', default: ' ' },
                    { key: 'components', default: ' ' },
                    { key: 'fixVersions', default: ' ' },
                    { key: 'pessimistic_estimate', default: ' ' },
                    { key: 'optimistic_estimate', default: ' ' },
                    { key: 'quoted_estimate', default: ' ' },
                    { key: 'original_estimate' },
                    { key: 'time_spent' },
                    { key: 'time_remaining' },
                    { key: 'total_estimate_time' },
                    { key: 'unforeseen_time' },
                    { key: 'nonbillable' },
                    { key: 'quoted_estimate_delta' },
                    { key: 'quoted_estimate_delta_perc', type: 'percent' },
                    { key: 'quoted_estimate_delta_perc' },
                    { key: 'quoted_estimate_delta_perc', type: 'percent' },
                ],
            },
        },
        {
            key: TYPES.SPRINT_BREAKDOWN,
            typeName: 'Sprint Breakdown',
            columns: [
                {
                    title: 'Type',
                    dataIndex: 'type',
                    key: 'type',
                    align: 'center',
                    width: COL_WIDTH_TINY,
                    render: (data, record) => renderImage(data, record),
                    ...ObjectFilter('type', dataSource),
                    filteredValue: filterInfo?.type || null,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    key: 'code',
                    align: 'left',
                    width: COL_WIDTH_XSM,
                    render: (data) => renderLink(data),
                    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
                    ...SelectionFilter('code', dataSource),
                    filteredValue: filterInfo?.code || null,
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    align: 'left',
                    width: COL_WIDTH_XSM,
                    ...SelectionFilter('status', dataSource),
                    filteredValue: filterInfo?.status || null,
                },
                {
                    title: 'Summary',
                    dataIndex: 'summary',
                    key: 'summary',
                    align: 'left',
                    width: COL_WIDTH_LRG,
                    ...SearchFilter('summary', 'Summary'),
                    filteredValue: filterInfo?.summary || null,
                },
                {
                    title: 'Priority',
                    dataIndex: 'priority',
                    key: 'priority',
                    align: 'center',
                    width: COL_WIDTH_TINY,
                    render: (data, record) => renderImage(data, record),
                    filteredValue: filterInfo?.priority || null,
                },
                {
                    title: 'Components',
                    dataIndex: 'components',
                    key: 'components',
                    align: 'left',
                    width: COL_WIDTH_LRG,
                    render: (data) => renderList(data),
                    ...NestedSelectionFilter('components', dataSource),
                    filteredValue: filterInfo?.components || null,
                },
                {
                    title: 'Fix Versions',
                    dataIndex: 'fixVersions',
                    key: 'fixVersions',
                    align: 'left',
                    width: COL_WIDTH_MID,
                    render: (data) => renderList(data),
                    ...NestedSelectionFilter('fixVersions', dataSource),
                    filteredValue: filterInfo?.fixVersions || null,
                },
                {
                    title: 'Original Estimate',
                    dataIndex: 'original_estimate',
                    key: 'original_estimate',
                    align: 'center',
                    width: COL_WIDTH_MID,
                    sorter: (a, b) => SimpleSorter(a, b, 'original_estimate'),
                    ...MinMaxFilter('original_estimate'),
                    filteredValue: filterInfo?.original_estimate || null,
                    sortOrder: sorterInfo?.columnKey === 'original_estimate' ? sorterInfo?.order : null,
                },
                {
                    title: 'Time Remaining',
                    dataIndex: 'time_remaining',
                    key: 'time_remaining',
                    align: 'center',
                    width: COL_WIDTH_MID,
                    sorter: (a, b) => SimpleSorter(a, b, 'time_remaining'),
                    ...MinMaxFilter('time_remaining'),
                    filteredValue: filterInfo?.time_remaining || null,
                    sortOrder: sorterInfo?.columnKey === 'time_remaining' ? sorterInfo?.order : null,
                },
                {
                    title: 'Total Billed',
                    dataIndex: 'total_billed',
                    key: 'total_billed',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    sorter: (a, b) => SimpleSorter(a, b, 'total_billed'),
                    ...MinMaxFilter('total_billed'),
                    filteredValue: filterInfo?.total_billed || null,
                    sortOrder: sorterInfo?.columnKey === 'total_billed' ? sorterInfo?.order : null,
                },
                {
                    title: 'Billed this Period',
                    dataIndex: 'time_spent',
                    key: 'time_spent',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    sorter: (a, b) => SimpleSorter(a, b, 'time_spent'),
                    ...MinMaxFilter('time_spent'),
                    filteredValue: filterInfo?.time_spent || null,
                    sortOrder: sorterInfo?.columnKey === 'time_spent' ? sorterInfo?.order : null,
                },
                {
                    title: 'Unforeseen Time',
                    dataIndex: 'unforeseen_time',
                    key: 'unforeseen_time',
                    align: 'center',
                    width: COL_WIDTH_MID,
                    sorter: (a, b) => SimpleSorter(a, b, 'unforeseen_time'),
                    ...MinMaxFilter('unforeseen_time'),
                    filteredValue: filterInfo?.unforeseen_time || null,
                    sortOrder: sorterInfo?.columnKey === 'unforeseen_time' ? sorterInfo?.order : null,
                },
                {
                    title: 'Not Billed Time',
                    dataIndex: 'nonbillable',
                    key: 'nonbillable',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    sorter: (a, b) => SimpleSorter(a, b, 'nonbillable'),
                    ...MinMaxFilter('nonbillable'),
                    filteredValue: filterInfo?.nonbillable || null,
                    sortOrder: sorterInfo?.columnKey === 'nonbillable' ? sorterInfo?.order : null,
                },
                {
                    title: 'Time Delta',
                    dataIndex: 'time_delta',
                    key: 'time_delta',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    format: 'percentWithoutMultiply',
                    sorter: (a, b) => PercentSorter(a, b, 'time_delta'),
                    ...MinMaxFilter('time_delta'),
                    filteredValue: filterInfo?.time_delta || null,
                    sortOrder: sorterInfo?.columnKey === 'time_delta' ? sorterInfo?.order : null,
                },
            ],
            summaryConfig: {
                initialColSpan: 0,
                columns: [
                    { key: 'status', default: ' ' },
                    { key: 'summary', default: ' ' },
                    { key: 'priority', default: ' ' },
                    { key: 'components', default: ' ' },
                    { key: 'fixVersions', default: ' ' },
                    { key: 'original_estimate' },
                    { key: 'time_remaining' },
                    { key: 'total_billed' },
                    { key: 'time_spent' },
                    { key: 'unforeseen_time' },
                    { key: 'nonbillable' },
                    { key: 'time_delta', type: 'percent' },
                ],
            },
        },
        {
            key: TYPES.BILLING_PERIOD_BREAKDOWN,
            typeName: 'Billing Period Breakdown',
            columns: [
                {
                    title: 'Type',
                    dataIndex: 'type',
                    key: 'type',
                    align: 'center',
                    width: COL_WIDTH_TINY,
                    render: (data) => renderImage(data),
                    ...ObjectFilter('type', dataSource),
                    filteredValue: filterInfo?.type || null,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    key: 'code',
                    align: 'left',
                    width: COL_WIDTH_XSM,
                    render: (data) => renderLink(data),
                    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
                    ...SelectionFilter('code', dataSource),
                    filteredValue: filterInfo?.code || null,
                },
                {
                    title: 'SSD Code',
                    dataIndex: 'ssdCode',
                    key: 'ssdCode',
                    align: 'left',
                    width: COL_WIDTH_SM,
                    ...SelectionFilter('ssdCode', dataSource),
                    filteredValue: filterInfo?.ssdCode || null,
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    align: 'left',
                    width: COL_WIDTH_XSM,
                    ...SelectionFilter('status', dataSource),
                    filteredValue: filterInfo?.status || null,
                },
                {
                    title: 'Summary',
                    dataIndex: 'summary',
                    key: 'summary',
                    align: 'left',
                    width: COL_WIDTH_LRG,
                    ...SearchFilter('summary', 'Summary'),
                    filteredValue: filterInfo?.summary || null,
                },
                {
                    title: 'Priority',
                    dataIndex: 'priority',
                    key: 'priority',
                    align: 'center',
                    width: COL_WIDTH_TINY,
                    render: (data, record) => renderImage(data, record),
                    filteredValue: filterInfo?.priority || null,
                },
                {
                    title: 'Components',
                    dataIndex: 'components',
                    key: 'components',
                    align: 'left',
                    width: COL_WIDTH_LRG,
                    render: (data) => renderList(data),
                    ...NestedSelectionFilter('components', dataSource),
                    filteredValue: filterInfo?.components || null,
                },
                {
                    title: 'Fix Versions',
                    dataIndex: 'fixVersions',
                    key: 'fixVersions',
                    align: 'left',
                    width: COL_WIDTH_MID,
                    render: (data) => renderList(data),
                    ...NestedSelectionFilter('fixVersions', dataSource),
                    filteredValue: filterInfo?.fixVersions || null,
                },
                {
                    title: 'Original Estimate',
                    dataIndex: 'original_estimate',
                    key: 'original_estimate',
                    align: 'center',
                    width: COL_WIDTH_MID,
                    sorter: (a, b) => SimpleSorter(a, b, 'original_estimate'),
                    filteredValue: filterInfo?.original_estimate || null,
                    sortOrder: sorterInfo?.columnKey === 'original_estimate' ? sorterInfo?.order : null,
                    ...MinMaxFilter('original_estimate'),
                },
                {
                    title: 'Time Remaining',
                    dataIndex: 'time_remaining',
                    key: 'time_remaining',
                    align: 'center',
                    width: COL_WIDTH_MID,
                    sorter: (a, b) => SimpleSorter(a, b, 'time_remaining'),
                    filteredValue: filterInfo?.time_remaining || null,
                    sortOrder: sorterInfo?.columnKey === 'time_remaining' ? sorterInfo?.order : null,
                    ...MinMaxFilter('time_remaining'),
                },
                {
                    title: 'Total Billed',
                    dataIndex: 'total_billed',
                    key: 'total_billed',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    sorter: (a, b) => SimpleSorter(a, b, 'total_billed'),
                    ...MinMaxFilter('total_billed'),
                    filteredValue: filterInfo?.total_billed || null,
                    sortOrder: sorterInfo?.columnKey === 'total_billed' ? sorterInfo?.order : null,
                },
                {
                    title: 'Billed this Period',
                    dataIndex: 'time_spent',
                    key: 'time_spent',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    sorter: (a, b) => SimpleSorter(a, b, 'time_spent'),
                    filteredValue: filterInfo?.time_spent || null,
                    sortOrder: sorterInfo?.columnKey === 'time_spent' ? sorterInfo?.order : null,
                    ...MinMaxFilter('time_spent'),
                },
                {
                    title: 'Unforeseen Time',
                    dataIndex: 'unforeseen_time',
                    key: 'unforeseen_time',
                    align: 'center',
                    width: COL_WIDTH_MID,
                    sorter: (a, b) => SimpleSorter(a, b, 'unforeseen_time'),
                    filteredValue: filterInfo?.unforeseen_time || null,
                    sortOrder: sorterInfo?.columnKey === 'unforeseen_time' ? sorterInfo?.order : null,
                    ...MinMaxFilter('unforeseen_time'),
                },
                {
                    title: 'Not Billed Time',
                    dataIndex: 'nonbillable',
                    key: 'nonbillable',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    sorter: (a, b) => SimpleSorter(a, b, 'nonbillable'),
                    filteredValue: filterInfo?.nonbillable || null,
                    sortOrder: sorterInfo?.columnKey === 'nonbillable' ? sorterInfo?.order : null,
                    ...MinMaxFilter('nonbillable'),
                },
                {
                    title: 'Time Delta',
                    dataIndex: 'time_delta',
                    key: 'time_delta',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    format: 'percentWithoutMultiply',
                    sorter: (a, b) => PercentSorter(a, b, 'time_delta'),
                    filteredValue: filterInfo?.time_delta || null,
                    sortOrder: sorterInfo?.columnKey === 'time_delta' ? sorterInfo?.order : null,
                    ...MinMaxFilter('time_delta'),
                },
            ],
            summaryConfig: {
                initialColSpan: 0,
                columns: [
                    { key: 'ssdCode', default: ' ' },
                    { key: 'status', default: ' ' },
                    { key: 'summary', default: ' ' },
                    { key: 'priority', default: ' ' },
                    { key: 'components', default: ' ' },
                    { key: 'fixVersions', default: ' ' },
                    { key: 'original_estimate' },
                    { key: 'time_remaining' },
                    { key: 'total_billed' },
                    { key: 'time_spent' },
                    { key: 'unforeseen_time' },
                    { key: 'nonbillable' },
                    { key: 'time_delta', type: 'percent' },
                ],
            },
        },
        {
            key: TYPES.SPRINT_PLAN,
            typeName: 'Sprint Plan',
            columns: [
                {
                    title: 'Type',
                    dataIndex: 'type',
                    key: 'type',
                    align: 'center',
                    width: COL_WIDTH_TINY,
                    render: (data, record) => renderImage(data, record),
                    ...ObjectFilter('type', dataSource),
                    filteredValue: filterInfo?.type || null,
                    sortOrder: sorterInfo?.columnKey === 'type' ? sorterInfo?.order : null,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    key: 'code',
                    align: 'left',
                    width: COL_WIDTH_XSM,
                    render: (data) => renderLink(data),
                    filterSearch: (input, record) => record.text.includes(input),
                    ...SelectionFilter('code', dataSource),
                    filteredValue: filterInfo?.code || null,
                    sortOrder: sorterInfo?.columnKey === 'code' ? sorterInfo?.order : null,
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    align: 'left',
                    width: COL_WIDTH_XSM,
                    ...SelectionFilter('status', dataSource),
                    filteredValue: filterInfo?.status || null,
                    sortOrder: sorterInfo?.columnKey === 'status' ? sorterInfo.order : null,
                },
                {
                    title: 'Summary',
                    dataIndex: 'summary',
                    key: 'summary',
                    align: 'left',
                    width: COL_WIDTH_LRG,
                    ...SearchFilter('summary', 'Summary'),
                    filteredValue: filterInfo?.summary || null,
                    sortOrder: sorterInfo?.columnKey === 'summary' ? sorterInfo?.order : null,
                },
                {
                    title: 'Priority',
                    dataIndex: 'priority',
                    key: 'priority',
                    align: 'center',
                    width: COL_WIDTH_TINY,
                    render: (data, record) => renderImage(data, record),
                    filteredValue: filterInfo?.priority || null,
                    sortOrder: sorterInfo?.columnKey === 'priority' ? sorterInfo?.order : null,
                },
                {
                    title: 'Components',
                    dataIndex: 'components',
                    key: 'components',
                    align: 'left',
                    width: COL_WIDTH_LRG,
                    render: (data) => renderList(data),
                    ...NestedSelectionFilter('components', dataSource),
                    filteredValue: filterInfo?.components || null,
                    sortOrder: sorterInfo?.columnKey === 'components' ? sorterInfo?.components : null,
                },
                {
                    title: 'Fix Versions',
                    dataIndex: 'fixVersions',
                    key: 'fixVersions',
                    align: 'left',
                    width: COL_WIDTH_MID,
                    render: (data) => renderList(data),
                    ...NestedSelectionFilter('fixVersions', dataSource),
                    filteredValue: filterInfo?.fixVersions || null,
                    sortOrder: sorterInfo?.columnKey === 'fixVersions' ? sorterInfo?.order : null,
                },
                {
                    title: 'Original Estimate',
                    dataIndex: 'original_estimate',
                    key: 'original_estimate',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    sorter: (a, b) => SimpleSorter(a, b, 'original_estimate'),
                    filteredValue: filterInfo?.original_estimate || null,
                    sortOrder: sorterInfo?.columnKey === 'original_estimate' ? sorterInfo?.order : null,
                    ...MinMaxFilter('original_estimate'),
                },
                {
                    title: 'Time Remaining',
                    dataIndex: 'time_remaining',
                    key: 'time_remaining',
                    align: 'center',
                    width: COL_WIDTH_SM,
                    sorter: (a, b) => SimpleSorter(a, b, 'time_remaining'),
                    filteredValue: filterInfo?.time_remaining || null,
                    sortOrder: sorterInfo?.columnKey === 'time_remaining' ? sorterInfo?.order : null,
                    ...MinMaxFilter('time_remaining'),
                },
            ],
            summaryConfig: {
                initialColSpan: 0,
                columns: [
                    { key: 'status', default: ' ' },
                    { key: 'summary', default: ' ' },
                    { key: 'priority', default: ' ' },
                    { key: 'components', default: ' ' },
                    { key: 'fixVersions', default: ' ' },
                    { key: 'original_estimate' },
                    { key: 'time_remaining' },
                ],
            },
        },
        {
            key: TYPES.BUG_RATE_ANALYSIS,
            typeName: 'Bug Rate Analysis',
            columns: [
                {
                    title: 'Type',
                    dataIndex: 'type',
                    key: 'type',
                    width: COL_WIDTH_TINY,
                    align: 'center',
                    render: (data, record) => renderImage(data, record),
                    ...ObjectFilter('type', dataSource),
                    filteredValue: filterInfo?.type || null,
                    sortOrder: sorterInfo?.columnKey === 'type' ? sorterInfo?.order : null,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    key: 'code',
                    width: COL_WIDTH_XSM,
                    align: 'center',
                    render: (data) => renderLink(data),
                    filterSearch: (input, record) => record.text.includes(input),
                    ...SelectionFilter('code', dataSource),
                    filteredValue: filterInfo?.code || null,
                    sortOrder: sorterInfo?.columnKey === 'code' ? sorterInfo?.order : null,
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    width: COL_WIDTH_XSM,
                    align: 'left',
                    ...SelectionFilter('status', dataSource),
                    filteredValue: filterInfo?.status || null,
                    sortOrder: sorterInfo?.columnKey === 'status' ? sorterInfo.order : null,
                },
                {
                    title: 'Summary',
                    dataIndex: 'summary',
                    key: 'summary',
                    align: 'left',
                    width: COL_WIDTH_LRG,
                    ...SearchFilter('summary', 'Summary'),
                    filteredValue: filterInfo?.summary || null,
                    sortOrder: sorterInfo?.columnKey === 'summary' ? sorterInfo?.order : null,
                },
                {
                    title: 'Assigned',
                    dataIndex: 'assignee',
                    key: 'assigned',
                    width: COL_WIDTH_LSM,
                    align: 'center',
                },
                {
                    title: 'Reporter',
                    dataIndex: 'reporter',
                    key: 'reporter',
                    width: COL_WIDTH_LSM,
                    align: 'center',
                },
                {
                    title: 'Priority',
                    dataIndex: 'priority',
                    key: 'proirity',
                    align: 'center',
                    width: COL_WIDTH_TINY,
                    render: (data, record) => renderImage(data, record),
                    filteredValue: filterInfo?.priority || null,
                    sortOrder: sorterInfo?.columnKey === 'priority' ? sorterInfo?.order : null,
                },
                {
                    title: 'Bug Rate',
                    dataIndex: 'bug_rate',
                    key: 'bug_rate',
                    width: COL_WIDTH_XSM,
                    align: 'center',
                    render: (data) => renderLink(data),
                    filterSearch: (input, record) => record.text.includes(input),
                    ...SelectionFilter('bug_rate', dataSource),
                    filteredValue: filterInfo?.bug_rate || null,
                    sortOrder: sorterInfo?.columnKey === 'bug_rate' ? sorterInfo?.order : null,
                },
                {
                    title: 'Original Estimate',
                    dataIndex: 'originalEstimate',
                    key: 'originalEstimate',
                    width: COL_WIDTH_SM,
                    align: 'center',
                    sorter: (a, b) => SimpleSorter(a, b, 'originalEstimate'),
                    filteredValue: filterInfo?.originalEstimate || null,
                    sortOrder: sorterInfo?.columnKey === 'originalEstimate' ? sorterInfo?.order : null,
                    ...MinMaxFilter('originalEstimate'),
                },
                {
                    title: 'Time Remaining',
                    dataIndex: 'timeRemaining',
                    key: 'timeRemaining',
                    width: COL_WIDTH_SM,
                    align: 'center',
                    sorter: (a, b) => SimpleSorter(a, b, 'timeRemaining'),
                    filteredValue: filterInfo?.timeRemaining || null,
                    sortOrder: sorterInfo?.columnKey === 'timeRemaining' ? sorterInfo?.order : null,
                    ...MinMaxFilter('timeRemaining'),
                },
                {
                    title: 'Time Spent',
                    dataIndex: 'timeSpent',
                    key: 'timeSpent',
                    width: COL_WIDTH_SM,
                    align: 'center',
                    sorter: (a, b) => SimpleSorter(a, b, 'timeSpent'),
                    filteredValue: filterInfo?.timeSpent || null,
                    sortOrder: sorterInfo?.columnKey === 'timeSpent' ? sorterInfo?.order : null,
                    ...MinMaxFilter('timeSpent'),
                },
            ],
        },
        {
            key: TYPES.BUG_TICKET_BY_PRIORITY,
            typeName: 'Bug Tickets By Priority',
            columns: [
                {
                    title: 'Priority',
                    key: 'priority',
                    align: 'center',
                    render: (data, record) => renderImage(data, record),
                    width: 200,
                },
                {
                    title: 'Tickets',
                    dataIndex: 'ticketsCount',
                    key: 'ticketsCount',
                    align: 'center',
                    width: 100,
                },
                {
                    title: 'Reopen Count',
                    dataIndex: 'reopenCount',
                    key: 'reopenCount',
                    align: 'center',
                    width: 100,
                },
                {
                    title: 'Original Estimate',
                    dataIndex: 'originalEstimate',
                    key: 'originalEstimate',
                    align: 'center',
                    width: 100,
                },
                {
                    title: 'Time Spent',
                    dataIndex: 'timeSpent',
                    key: 'timeSpent',
                    align: 'center',
                    width: 100,
                },
                {
                    title: 'Time Remaining',
                    dataIndex: 'timeRemaining',
                    key: 'timeRemaining',
                    align: 'center',
                    width: 100,
                },
            ],
            summaryConfig: {
                initialColSpan: 0,
                columns: [
                    { key: 'priority', default: 'Total' },
                    { key: 'ticketsCount' },
                    { key: 'reopenCount' },
                    { key: 'originalEstimate' },
                    { key: 'timeSpent' },
                    { key: 'timeRemaining' },
                ],
            },
        },
        {
            key: TYPES.WATERFAL_BUG_COMPONENT,
            typeName: 'Bugs By Component',
            columns: [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    width: 200,
                },
                {
                    title: 'High Priority',
                    dataIndex: 'highPriority',
                    key: 'highPriority',
                    align: 'center',
                    width: 75,
                },
                {
                    title: 'Medium Priority',
                    dataIndex: 'mediumPriority',
                    key: 'mediumPriority',
                    align: 'center',
                    width: 75,
                },
                {
                    title: 'Low Priority',
                    dataIndex: 'lowPriority',
                    key: 'low_priority',
                    align: 'center',
                    width: 75,
                },
                {
                    title: 'Reopen Count',
                    dataIndex: 'reopenCount',
                    key: 'reopenCount',
                    align: 'center',
                    width: 75,
                },
                {
                    title: 'Original Estimate',
                    dataIndex: 'originalEstimate',
                    key: 'original_estimate',
                    align: 'center',
                    width: 100,
                },
                {
                    title: 'Time Spent',
                    dataIndex: 'timeSpent',
                    key: 'timeSpent',
                    align: 'center',
                    width: 75,
                },
                {
                    title: 'Time Remaining',
                    dataIndex: 'timeRemaining',
                    key: 'time_remaining',
                    align: 'center',
                    width: 75,
                },
                {
                    title: 'Bug Rate',
                    dataIndex: 'bugRate',
                    key: 'bugRate',
                    align: 'center',
                    width: 75,
                    format: 'percent',
                },
            ],
            summaryConfig: {
                initialColSpan: 0,
                columns: [
                    { key: 'name', default: 'Total' },
                    { key: 'highPriority' },
                    { key: 'mediumPriority' },
                    { key: 'lowPriority' },
                    { key: 'reopenCount' },
                    { key: 'originalEstimate' },
                    { key: 'timeSpent' },
                    { key: 'timeRemaining' },
                    { key: 'bugRate', type: 'percent' },
                ],
            },
        },
        {
            key: TYPES.WATERFALL_BUG_RATE_ANALAYSIS,
            typeName: 'Bug Rate Analysis',
            columns: [
                {
                    title: 'Type',
                    dataIndex: 'type',
                    key: 'type',
                    align: 'center',
                    width: COL_WIDTH_TINY,
                    fixed: 'left',
                    render: (data) => renderImage(data),
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    key: 'code',
                    align: 'left',
                    width: COL_WIDTH_XSM,
                    fixed: 'left',
                    render: (data) => renderLink(data),
                    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
                    ...SelectionFilter('code', dataSource),
                    filteredValue: filterInfo?.code || null,
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    align: 'left',
                    width: COL_WIDTH_XSM,
                    ...SelectionFilter('status', dataSource),
                    filteredValue: filterInfo?.status || null,
                    sortOrder: sorterInfo?.columnKey === 'status' ? sorterInfo.order : null,
                },
                {
                    title: 'Summary',
                    dataIndex: 'summary',
                    key: 'summary',
                    align: 'left',
                    width: COL_WIDTH_LRG,
                    ...SearchFilter('summary', 'Summary'),
                    filteredValue: filterInfo?.summary || null,
                },
                {
                    title: 'Assignee',
                    dataIndex: 'assignee',
                    key: 'assignee',
                    align: 'left',
                    width: COL_WIDTH_SM,
                    ...SelectionFilter('assignee', dataSource),
                    filteredValue: filterInfo?.assignee || null,
                },
                {
                    title: 'Reporter',
                    dataIndex: 'reporter',
                    key: 'reporter',
                    align: 'left',
                    width: COL_WIDTH_SM,
                    ...SelectionFilter('reporter', dataSource),
                    filteredValue: filterInfo?.reporter || null,
                },
                {
                    title: 'Priority',
                    dataIndex: 'priority',
                    key: 'priority',
                    align: 'center',
                    width: COL_WIDTH_TINY,
                    render: (data, record) => renderImage(data, record),
                    ...ObjectFilter('priority', dataSource),
                    filteredValue: filterInfo?.priority || null,
                },
                {
                    title: 'Original Estimate',
                    dataIndex: 'originalEstimate',
                    key: 'originalEstimate',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                    sorter: (a, b) => SimpleSorter(a, b, 'originalEstimate'),
                    ...MinMaxFilter('originalEstimate'),
                    filteredValue: filterInfo?.originalEstimate || null,
                    sortOrder: sorterInfo?.columnKey === 'originalEstimate' ? sorterInfo.order : null,
                },
                {
                    title: 'Time Spent',
                    dataIndex: 'timeSpent',
                    key: 'timeSpent',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                    sorter: (a, b) => SimpleSorter(a, b, 'timeSpent'),
                    ...MinMaxFilter('timeSpent'),
                    filteredValue: filterInfo?.timeSpent || null,
                    sortOrder: sorterInfo?.columnKey === 'timeSpent' ? sorterInfo.order : null,
                },
                {
                    title: 'Time Remaining',
                    dataIndex: 'timeRemaining',
                    key: 'timeRemaining',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                    sorter: (a, b) => SimpleSorter(a, b, 'timeRemaining'),
                    ...MinMaxFilter('timeRemaining'),
                    filteredValue: filterInfo?.timeRemaining || null,
                    sortOrder: sorterInfo?.columnKey === 'timeRemaining' ? sorterInfo.order : null,
                },
                {
                    title: 'Components',
                    dataIndex: 'components',
                    key: 'components',
                    align: 'left',
                    width: COL_WIDTH_SM,
                    render: (data) => renderList(data),
                    ...NestedSelectionFilter('components', dataSource),
                    filteredValue: filterInfo?.components || null,
                },
            ],
            summaryConfig: {
                initialColSpan: 0,
                columns: [
                    { key: 'type', default: ' ' },
                    { key: 'summary', default: ' ' },
                    { key: 'assignee', default: ' ' },
                    { key: 'reporter', default: ' ' },
                    { key: 'priority', default: ' ' },
                    { key: 'originalEstimate' },
                    { key: 'timeSpent' },
                    { key: 'timeRemaining' },
                    { key: 'components', default: ' ' },
                ],
            },
        },
        {
            key: TYPES.ESTIMATE_PEV_DATA_TABLE,
            typeName: 'PEV Data Table',
            columns: [
                {
                    title: 'Estimate',
                    dataIndex: 'estimate',
                    key: 'estimate',
                    align: 'center',
                    width: COL_WIDTH_LSM,
                    filteredValue: filterInfo?.type || null,
                },
                {
                    title: 'Optimistic Estimate',
                    dataIndex: 'optimistic_estimate',
                    key: 'optimistic_estimate',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                    filteredValue: filterInfo?.type || null,
                },
                {
                    title: 'Most Likely Estimate',
                    dataIndex: 'most_likely_estimate',
                    key: 'most_likely_estimate',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
                    filteredValue: filterInfo?.code || null,
                },
                {
                    title: 'Revised Estimate',
                    dataIndex: 'revised_estimate',
                    key: 'revised_estimate',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
                    filteredValue: filterInfo?.code || null,
                },
                {
                    title: 'Δ Hours (Most Likely to Revised Estimate)',
                    dataIndex: 'most_likely_to_revised_estimate',
                    key: 'most_likely_to_revised_estimate',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
                    filteredValue: filterInfo?.code || null,
                },
                {
                    title: 'Hours Remaining',
                    dataIndex: 'hours_remaining',
                    key: 'hours_remaining',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
                    filteredValue: filterInfo?.code || null,
                },
                {
                    title: 'Pessimistic Estimate',
                    dataIndex: 'pessimistic_estimate',
                    key: 'pessimistic_estimate',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
                    filteredValue: filterInfo?.code || null,
                },
            ],
            summaryConfig: {
                title: '% Increase in requirements, and therefore time and cost',
                hideTotal: true,
                initialColSpan: 0,
                columns: [
                    { key: 'optimistic_estimate' },
                ],
            },
        },
        {
            key: TYPES.TOTAL_TIME_SPENT_PEV_DATA_TABLE,
            typeName: 'PEV Data Table',
            columns: [
                {
                    title: 'Actual Time',
                    dataIndex: 'actual_time',
                    key: 'actual_time',
                    align: 'center',
                    width: COL_WIDTH_LSM,
                    filteredValue: filterInfo?.type || null,
                },
                {
                    title: 'Total Time',
                    dataIndex: 'total_time',
                    key: 'total_time',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                    filteredValue: filterInfo?.type || null,
                },
                {
                    title: 'Not Billed Time',
                    dataIndex: 'non_billable',
                    key: 'non_billable',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
                    filteredValue: filterInfo?.code || null,
                },
                {
                    title: 'Total Billable',
                    dataIndex: 'total_billable',
                    key: 'total_billable',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
                    filteredValue: filterInfo?.code || null,
                },
            ],
        },
        {
            key: TYPES.OVERVIEW_PEV_DATA_TABLE,
            typeName: 'PEV Data Table',
            columns: [
                {
                    title: ' ',
                    dataIndex: 'overview_time',
                    key: 'overview_time',
                    align: 'center',
                    width: COL_WIDTH_TINY,
                },
                {
                    title: 'Total Dev Estimate',
                    dataIndex: 'total_dev_estimate',
                    key: 'total_dev_estimate',
                    align: 'center',
                    width: COL_WIDTH_TINY,
                },
                {
                    title: 'Total Dev Remaining',
                    dataIndex: 'total_dev_remaining',
                    key: 'total_dev_remaining',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                },
                {
                    title: 'Total Dev Completed',
                    dataIndex: 'total_dev_completed',
                    key: 'total_dev_completed',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                },
                {
                    title: 'Actual Time Used',
                    dataIndex: 'actual_time_used',
                    key: 'actual_time_used',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                },
                {
                    title: 'Total cost based on remaining hours as originally estimated',
                    dataIndex: 'total_cost',
                    key: 'total_cost',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                },
                {
                    title: 'Δ Time Used',
                    dataIndex: 'time_used',
                    key: 'time_used',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                },
                {
                    title: '%Δ',
                    dataIndex: 'delta',
                    key: 'delta',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                },
                {
                    title: 'Updated hours based on % change',
                    dataIndex: 'updated_hours',
                    key: 'updated_hours',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                },
            ],
        },
        {
            key: TYPES.COMPARISON_PEV_DATA_TABLE,
            typeName: 'PEV Data Table',
            columns: [
                {
                    title: ' ',
                    dataIndex: 'estimate',
                    key: 'estimate',
                    align: 'center',
                    width: COL_WIDTH_TINY,
                },
                {
                    title: 'Budget',
                    key: 'budget',
                    align: 'center',
                    children: [
                        {
                            title: 'Hours',
                            dataIndex: 'hours',
                            key: 'hours',
                            align: 'center',
                            width: COL_WIDTH_TINY,
                        },
                        {
                            title: 'Cost',
                            dataIndex: 'cost',
                            key: 'cost',
                            align: 'center',
                            width: COL_WIDTH_TINY,
                        },
                    ],
                },
                {
                    title: 'Comparison to base line estimates',
                    key: 'comparison_to_base_line_estimates',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                    children: [
                        {
                            title: 'Revised Hours',
                            dataIndex: 'comparison_revised_hours',
                            key: 'comparison_revised_hours',
                            align: 'center',
                            width: COL_WIDTH_TINY,
                        },
                        {
                            title: 'Revised Cost',
                            dataIndex: 'comparison_revised_cost',
                            key: 'comparison_revised_cost',
                            align: 'center',
                            width: COL_WIDTH_TINY,
                        },
                        {
                            title: 'Delta Cost',
                            dataIndex: 'comparison_delta_cost',
                            key: 'comparison_delta_cost',
                            align: 'center',
                            width: COL_WIDTH_TINY,
                        },
                        {
                            title: 'Delta % Cost',
                            dataIndex: 'comparison_delta_percent_cost',
                            key: 'comparison_delta_percent_cost',
                            align: 'center',
                            width: COL_WIDTH_TINY,
                        },
                    ],
                },
                {
                    title: 'Projection based on current allocation of spent hours',
                    key: 'projection_based_on_current_allocation_of_spent_hours',
                    align: 'center',
                    width: COL_WIDTH_XSM,
                    children: [
                        {
                            title: 'Revised Hours',
                            dataIndex: 'projection_revised_hours',
                            key: 'projection_revised_hours',
                            align: 'center',
                            width: COL_WIDTH_TINY,
                        },
                        {
                            title: 'Revised Cost',
                            dataIndex: 'projection_revised_cost',
                            key: 'projection_revised_cost',
                            align: 'center',
                            width: COL_WIDTH_TINY,
                        },
                        {
                            title: 'Delta Cost',
                            dataIndex: 'projection_delta_cost',
                            key: 'projection_delta_cost',
                            align: 'center',
                            width: COL_WIDTH_TINY,
                        },
                        {
                            title: 'Delta % Cost',
                            dataIndex: 'projection_delta_percent_cost',
                            key: 'projection_delta_percent_cost',
                            align: 'center',
                            width: COL_WIDTH_TINY,
                        },
                    ],
                },
            ],
        },
    ];

    return config?.find(c => c?.key === type);
};

export default getConfig;
